<template>
    <div class="platform-creator">
        <Banner v-bind="banner">
            <template slot="btn">
                <div class="cyxq-box">
                    <button class="ym-btn-3" @click="callus()">咨询专家 &rarr;</button>
                    <button class="ym-btn-3" @click="startUse()">开始使用 &rarr;</button>
                </div>
            </template>
        </Banner>
        <div ref="subNavBar" class="sub-nav-bar d-md-block d-none" :class="{ allin: subNavBarShowAll }"
            v-if="body.hasNav">
            <div class="container">
                <span class="h1">{{ banner.title }}</span>
                <span class="nav-link" v-for="item in body.list" :class="{ active: item.id === activeNavLink }"
                    @click="scroll(item.id)">{{ item.title }}</span>
                <button class="btn" @click="startUse()">立即使用</button>
            </div>
        </div>
        <article class="article">
            <section class="desc-card" v-for="(item, index) in body.list" :key="item.id"
                :class="[item.id, { 'desc-card-middle': index + 1 !== body.list.length }]" :id="item.id">
                <!-- 核心问题 -->
                <div v-if="item.id === 'core-issues'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <ul class="d-flex justify-content-around">
                            <li class="sq-card" v-for="issues in item.arr">
                                <img class="icon" width="40px" height="40px" :src="issues.imgUrl" alt="">
                                <p class="text">{{ issues.text }}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 适用行业 -->
                <div v-if="item.id === 'suitable-industry'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <ul class="flex-box">
                            <li class="flex-item" v-for="industry in item.arr">
                                <img class="icon" :src="industry.imgUrl" alt="">
                                <p class="text">{{ industry.text }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 主要功能 -->
                <div v-if="item.id === 'main-function'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <ul class="flex-box">
                            <li class="flex-item" v-for="func in item.arr">
                                <div class="icon-title-text">
                                    <span class="icon">
                                        <img :src="func.imgUrl" alt="">
                                    </span>
                                    <div class="title-text">
                                        <h3 class="sub-title">{{ func.subTitle }}</h3>
                                        <h2 class="title">{{ func.title }}</h2>
                                        <p class="text">{{ func.text }}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 方案架构 -->
                <div v-if="item.id === 'scheme-architecture'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <div class="tab-box" v-if="item.arr.length - 1">
                            <!-- 这个地方偷懒了.没改数据结构。要是有添加的话请小心呐 ^-^ :) -->
                            <span class="tab" v-for="(item, index) in item.arr" :class="{ active: current === index }"
                                @click="current = index">{{ index ? '危化园区' : '危化企业' }}</span>
                        </div>
                        <img v-show="current === index" v-for="(imgUrl, index) in item.arr" width="100%" height="100%"
                            :src="imgUrl" alt="方案架构">
                    </div>
                </div>
                <!-- 方案优势 -->
                <div v-if="item.id === 'scheme-advantages'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <ul class="d-flex flex-wrap adv-box">
                            <li class="adv-info" :class="item.layoutclass" v-for="adv in item.arr">
                                <IconTitleText :icon="'icon-sure'" :ptitle="adv.title" :ptext="adv.text">
                                </IconTitleText>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 选择您合适的版本 -->
                <div v-if="item.id === 'suitable-version'" class="container">
                    <header class="desc-card-header">
                        <h1 class="title">{{ item.title }}</h1>
                    </header>
                    <div class="desc-card-body">
                        <div class="flex-box">
                            <!-- 普惠版 -->
                            <div class="free">
                                <h1 class="title">普惠版</h1>
                                <p class="text">{{ item.free.text }}</p>
                                <ul class="menus">
                                    <li class="menu" v-for="menu in item.free.menus">{{ menu }}</li>
                                </ul>
                                <button class="btn" @click="startUse()">免费使用</button>
                            </div>
                            <!-- 专业版 -->
                            <div class="professional">
                                <h1 class="title">专业版</h1>
                                <p class="text">{{ item.professional.text }}</p>
                                <div class="other-info">包含普惠版完整功能及:</div>
                                <ul class="menus">
                                    <li class="menu" v-for="menu in item.professional.menus">{{ menu }}</li>
                                </ul>
                                <button class="btn" @click="callus()">立即咨询</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    </div>
</template>

<script>
import Banner from '@/components/Banner.vue';
import IconTitleText from './IconTitleText.vue';
import { PPCreatorMixin } from './mixin';

export default {
    name: "platform-creator",
    props: {
        banner: {
            type: Object,
            require: true
        },
        body: {
            type: Object,
            require: true
        },
    },
    components: { Banner, IconTitleText },
    data() {
        return {
            current: 0,
        }
    },
    mixins: [PPCreatorMixin],
}
</script>

<style lang="scss">
@import "@/sass/util.scss";

.platform-creator {

    // banner图
    .banner {
        .illustration {
            display: flex;
            max-width: 50%;
        }
    }

    // 核心问题
    .core-issues {
        text-align: center;

        .sq-card {
            background: #FFFFFF;
            box-shadow: 0px 2px 4px 4px rgba(209, 209, 209, 0.3000), 0px 2px 1px 2px rgba(228, 228, 228, 0.5000);
            border-radius: 8px;
            padding: 4rem 5rem;

            @include media-breakpoint-down(md) {
                padding: 2rem 3rem;
            }

            .text {
                white-space: pre;
                text-align: center;
            }
        }
    }

    // 方案优势
    .scheme-advantages {
        .adv-box {
            margin: 0 -4rem;
            row-gap: 5rem;
        }

        .adv-info {
            .icon-title-text {
                padding: 0 4rem;
                @include icon-title-text(1.25rem, 10px, 1.25rem, 1rem, 8px);

                .text {
                    text-align: justify;
                }

                .icon {
                    .icon-sure {
                        background-image: url("@/assets/images/product/icons/sure.png");
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    // 适用行业
    .suitable-industry {
        .flex-box {
            display: flex;
            text-align: center;
            flex-wrap: wrap;
            row-gap: 4.5rem;

            .flex-item {
                .icon {
                    width: 6.875rem;
                    height: 6.875rem;
                    border-radius: 50%;
                }

                &:not(:nth-child(5n)) {
                    margin-right: calc(25% - 8.59375rem - 1px);
                }
            }
        }

    }

    // 主要功能
    .main-function {
        .flex-box {
            display: flex;
            flex-wrap: wrap;
            row-gap: 4em;
        }

        .icon-title-text {
            @include icon-title-text(3.75rem, 1.5rem, 1.5rem, 1rem, 1rem);

            .sub-title {
                font-size: 1.125rem;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    // 方案架构
    .scheme-architecture {
        .tab-box {
            width: 180px;
            border-radius: 5px;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2rem;
            margin-bottom: 20px;
            background-color: #D8D8D8;
            display: flex;

            .tab {
                width: 90px;
                height: 100%;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
                padding: 0.4em 0;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-size: 14px;

                &.active {
                    background-color: $ym-main-color;
                    color: #fff;
                }
            }
        }
    }

    // 适合版本
    .suitable-version {

        background: linear-gradient(to bottom, #fff, #fff 60%, #F5F7FB 60%, #F5F7FB 100%);

        .flex-box {
            display: flex;
            text-align: center;
            justify-content: center;
        }

        %base {
            min-width: 20em;
            max-width: 25em;
            flex-grow: 1;
            padding-top: 30px;
            padding-bottom: 160px;
            border-radius: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 1px;

            .title {
                font-size: 2.25rem;
                font-weight: 500;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            }

            .text {
                font-size: 0.875rem;
                font-weight: 400;
                margin-bottom: 40px;
            }

            .menu {
                display: flex;
                align-items: center;
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 2em;

                &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-size: 100%;
                }
            }

            .btn {
                width: 10em;
                height: 3em;
                background: #FFFFFF;
                border-radius: 1.5em;
                font-size: 1.125rem;
                color: $ym-main-color;
                cursor: pointer;
                position: absolute;
                bottom: 30px;
                left: 0;
                right: 0;
                margin: auto;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            }
        }

        .free {
            @extend %base;
            background: #FFFFFF;
            border: 1px solid #EB6C11;
            margin-right: 3.375rem;


            .title {
                color: #333333;
            }

            .text {
                color: #666;
            }


            .menu::before {
                background-image: url(~@/assets/images/product/icons/sure-dark.png);
            }

            .btn {
                box-shadow: 0 0 0 1px $ym-main-color;
            }
        }

        .professional {
            @extend %base;
            background: linear-gradient(180deg, #F29E4A 0%, #EB680C 100%);
            color: #FFFFFF;


            .other-info {
                font-size: 1.25rem;
                font-weight: 500;
            }

            .menu::before {
                background-image: url(~@/assets/images/product/icons/sure-light.png);
            }

            .btn {
                box-shadow: 0px 2px 4px 0px rgba(133, 133, 133, 0.5000);
            }
        }

        @include media-breakpoint-down(md) {
            .flex-box {
                flex-wrap: wrap;
            }

            .free {
                margin-right: 0;
                margin-bottom: 4em;
            }
        }

    }
}
</style>

<style lang="scss">
.page-coldchain,
.page-hc {
    #scheme-advantages {
        .adv-info {
            min-width: 320px;
        }

    }
}
</style>
<style lang="scss">
.page-Carrier {
    .main-function {
        .flex-box {
            justify-content: space-between;


            .flex-item:nth-child(3n+1) {
                width: 20em;
            }

            .flex-item:nth-child(3n+2) {
                width: 20em;
            }

            .flex-item:nth-child(3n+3) {
                width: 17em;
            }
        }
    }
}

.page-manufacturing-and-trade {
    .main-function {
        .flex-box {
            justify-content: space-between;


            .flex-item:nth-child(3n+1) {
                width: 20em;
            }

            .flex-item:nth-child(3n+2) {
                width: 21em;
            }

            .flex-item:nth-child(3n+3) {
                width: 15em;
            }
        }
    }
}
</style>