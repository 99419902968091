<template>
    <section class="desc-card" :class='{ "desc-card-middle": middle }'>
        <div class="container">
            <header class="desc-card-header" v-show="subTitle || title">
                <h2 class="sub-title">{{ subTitle }}</h2>
                <h1 class="title">{{ title }}</h1>
            </header>
            <p class="desc-card-body">
                <slot></slot>
            </p>
        </div>
    </section>
</template>

<script>
export default {
    emit: ["click"],
    props: {
        title: String,
        subTitle: String,
        textbtn: String,
        footer: {
            type: Boolean,
            default: true
        },
        middle: {
            type: Boolean,
            default: false
        },
        container: {
            type: Boolean,
            default: true
        }
    },
    name: "desc-temp1"
}
</script>

<style lang="scss">
@import "@/sass/util";

.desc-card {
    padding-top: 8rem;
    padding-bottom: 8rem;

    .desc-card-header {
        position: relative;
        text-align: center;
        overflow: visible;
        margin-bottom: 5rem;

        .title {
            font-size: 3rem;
            font-weight: bold;
            color: #333333;
        }

        .sub-title {
            position: absolute;
            font-size: 1.5rem;
            font-weight: 400;
            color: #666666;
            top: -2.5rem;
            left: 0;
            right: 0;
        }
    }

    .desc-card-body {
        width: 100%;
    }

    &.desc-card-middle {
        padding-bottom: 0;
    }
}
</style>