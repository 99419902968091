<template>
    <div class="banner">
        <div class="banner-box" :style="{ background: background }">
            <div class="container" :class="{ reserver: isreserver }">
                <div class="text-area" :class="fontColorTheme">
                    <div>
                        <h2 class="prefix-title" v-if="prefixTitle">{{ prefixTitle }}</h2>
                        <h1 class="title" v-show="title">{{ title }}</h1>
                        <h2 class="suffix-title" v-if="suffixTitle">{{ suffixTitle }}</h2>
                        <p class="text">{{ text }}</p>
                        <div class="btn-box" v-if="$slots.btn">
                            <slot name="btn"></slot>
                        </div>
                    </div>
                </div>
                <div class="illustration" :style="illustration">
                    <div class="slot-img">
                        <img v-if="imgUrl" :src="imgUrl" alt="">
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        background: {
            type: String,
            default: ""
        },
        // banner标题
        title: {
            type: String,
            default: "",
        },
        // banner下标题
        suffixTitle: {
            type: String,
            default: "",
        },
        // banner上标题
        prefixTitle: {
            type: String,
            default: "",
        },
        // banner文本。支持换行
        text: {
            type: String,
            default: "",
        },
        // 字体颜色主题 dark|light 黑色或白色
        fontColorTheme: {
            type: String,
            default: "dark",
        },
        // 配图的background配置。背景图
        illustration: {
            type: Object,
            default() {
                return {}
            }
        },
        // 是否将文本域与图片对调位置
        isreserver: {
            type: Boolean,
            default: false
        },
        imgUrl: {
            type: String,
            default: ""
        },
        btnlist: {
            type: Array,
            default() {
                return []
            }
        }
    },
}
</script>

<style lang="scss">
@import "@/sass/util";

.banner {
    margin-top: 60px;
    position: relative;
    padding-top: 480px;

    .banner-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .container {
        display: flex;
        align-items: center;
        height: 100%;

        &.reserver {
            flex-direction: row-reverse;

            .text-area {
                padding-left: 4rem;
            }
        }

        // 文本域的大小
        .text-area {
            flex-shrink: 0;
            min-width: 50%;
            max-width: 64%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;

            .btn-box {
                margin-top: 50px;
            }

            &.dark {
                .title {
                    color: #333;
                }

                .text {
                    color: #666;
                }

                .prefix-title {
                    color: #333;
                }
            }

            &.main {
                .title {
                    color: $ym-main-color;
                }

                .text {
                    color: #666;
                }
            }

            &.light {
                color: #fff;
            }

            .title {
                font-size: 3.375rem;
                margin-bottom: 1rem;
                font-weight: bold;
            }

            .prefix-title {
                font-size: 2.25rem;
                margin-bottom: 1rem;
                font-weight: bold;
            }

            .text {
                white-space: pre;
                font-size: 1.125rem;
                font-weight: 400;
            }
        }


        // 插图的大小
        .illustration {
            flex-grow: 1;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }



        @include media-breakpoint-down(md) {
            flex-direction: column !important;
            padding: 2em 0;

            .text-area {
                width: 100%;
                text-align: center;
                height: auto;
            }

            .illustration {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 360px;
    }

    .slot-img {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            height: 100%;
            right: -12rem;
        }

        svg {
            position: absolute;
            height: 100%;
            right: 0;
        }

    }

}
</style>