import { elementScrollShow } from "@/utils/elementScrollShow"

// 
/**
 * 注意点。需要有这个属性 
 * body:{list:[{id:""}]}
 */
export const PPCreatorMixin = {
    data() {
        return {
            subNavBarShowAll: false,
            activeNavLink: ""
        }
    },
    methods: {
        subNavBarChange() {
            let top = 60
            try {
                const position = this.$refs.subNavBar.getBoundingClientRect()
                top = position.top
            } catch (e) { }

            if (top === 60) {
                this.subNavBarShowAll = true
            } else {
                this.subNavBarShowAll = false
            }
        },
        scroll(id) {
            elementScrollShow(document.getElementById(id), -132)
        },
    },
    mounted() {
        if (this.$refs.subNavBar) {
            const subNavBar = this.$refs.subNavBar
            const titleEle = subNavBar.getElementsByClassName("h1")[0]
            this.subNavBarChange()

            subNavBar.$scrollListener = (e) => {
                this.subNavBarChange()

                if (this.subNavBarShowAll) {
                    titleEle.style.width = titleEle.scrollWidth + "px"
                } else {
                    titleEle.style.width = "0"
                }
                clearTimeout(subNavBar.checkTimer)
                subNavBar.checkTimer = setTimeout(v => {
                    // 当前滚动高度
                    const top = document.documentElement.scrollTop + 132
                    for (let i = 0; i < this.body.list.length; i++) {
                        const element = document.getElementById(this.body.list[i].id)
                        const min = element.offsetTop
                        const max = min + element.scrollHeight - 1
                        if (top >= min && top < max) {
                            console.log(min, top, max, this.body.list[i])
                            this.$set(this, "activeNavLink", this.body.list[i].id)
                            break
                        }
                    }
                }, 100)
            }
            document.addEventListener('scroll', subNavBar.$scrollListener)
        }
    },
    beforeDestroy() {
        try {
            document.removeEventListener('scroll', this.$refs.subNavBar.$scrollListener)
        } catch {
        }
    }
}