<template>
    <DescTemp title="主要系统" :middle="true">
        <div class="main-system-card">
            <ul class="menu-list">
                <li @mouseenter="change(index)" @mouseleave="autoStart()" class="menu-item"
                    v-for="(item, index) in dict" :class="{ active: index === current }">
                    {{ item.menu }}
                </li>
            </ul>
            <div class="desc">
                <ul class="content-box">
                    <li class="content-card" :class="{ current: index === current }" v-for="(item, index) in dict" :key="item.to">
                        <img :src="item.imgUrl" :alt="item.desc">
                        <p>{{ item.desc }}</p>
                        <router-link class="ym-btn-t3" :to="{ name: item.to }">了解更多</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </DescTemp>
</template>

<script>
import DescTemp from '@/components/DescTemp.vue';

export default {
    components: { DescTemp },
    data() {
        const dict = [
            {
                menu: "OMS订单管理系统",
                desc: "运输订单在线化跨组织流转，实现一单到底、全程追溯，帮助企业高效、智能地处理订单",
                imgUrl: require("@/assets/images/product/illustration/订单管理.png"),
                to: "OMS"
            },
            {
                menu: "TMS运输管理系统",
                desc: "适配多种细分运输类型的运输业务管理系统，帮助物流企业打通业务协作能力，有效提高运输效率",
                imgUrl: require("@/assets/images/product/illustration/运输管理.png"),
                to: "TMS"
            },
            {
                menu: "WMS仓储管理系统",
                desc: "精细化仓储及仓配一体化管理，提高出入仓工作效率",
                imgUrl: require("@/assets/images/product/illustration/仓储管理.png"),
                to: "WMS"

            },
            {
                menu: "数智车务管理系统",
                desc: "帮助企业从购车、维修、保养、保险等方面全周期管理车辆，提高车辆利用效率，合理统筹车务管理支出，智能提醒车务相关事项",
                imgUrl: require("@/assets/images/product/illustration/数智车务.png"),
                to: "MIC"
            },
            {
                menu: "CRM客户管理系统",
                desc: "分级分层精准化客户管理，帮助企业提升对客户的服务水平",
                imgUrl: require("@/assets/images/product/illustration/客户管理.png"),
                to: "CRM"

            },
            {
                menu: "FMS财务管理系统",
                desc: "业务、财务数据互联互通，简化财务工作，优化成本管控",
                imgUrl: require("@/assets/images/product/illustration/财务管理.png"),
                to: "FMS"
            },
            {
                menu: "HR人力管理系统",
                desc: "司机及安全员数字化管理，并提供有大数据画像支持的专业人员招聘",
                imgUrl: require("@/assets/images/product/illustration/司机档案.png"),
                to: "HR"
            },
            {
                menu: "企业OA系统",
                desc: "以组织化规范企业管理，帮助企业实现在线数字化流程管理，提升跨部门协作效率",
                imgUrl: require("@/assets/images/product/illustration/组织与权限.png"),
                to: "OA"
            }
        ]
        return {
            current: 0,
            dict,
            timer: null,
        }
    },
    methods: {
        change(index, isAuto = false) {
            this.current = index
            if (!isAuto) {
                clearInterval(this.timer)
            }
        },
        autoStart() {
            this.timer = setInterval(() => {
                const next = (this.current + 1) % 8
                this.change(next, true)
            }, 3000)
        }
    },
    created() {
        this.$nextTick(_ => {
            this.autoStart()
        })
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
@import "@/sass/util";

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.ym-btn-t3 {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 150px;
    height: 52px;
    background: linear-gradient(135deg, #F7A742 0%, #ED6E1F 100%);
    border-radius: 26px;
}

.main-system-card {
    width: 100%;
    height: 40rem;
    background: #FBFBFB;
    box-shadow: 0px 2px 6px 2px rgba(228, 228, 228, 0.5000);
    border-radius: 16px;
    display: flex;
    align-items: center;

    .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        flex-shrink: 0;
    }

    .menu-item {
        width: 100%;
        padding: 0.8em 1.6em;
        background: linear-gradient(46deg, #FFFFFF 0%, #F1F1F1 100%);
        // background: #F1F1F1;
        box-shadow: 1px 1px 2px 0px rgba(132, 132, 132, 0.5000), 2px 2px 1px 0px rgba(197, 197, 197, 0.4000), inset 2px 2px 1px 0px #FFFFFF, inset 0px 1px 3px 0px rgba(236, 236, 236, 0.8000);
        border-radius: 0px 16px 0px 0px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &.active {
            transition: all .3s ease-in-out;
            background: linear-gradient(135deg, #F7A742 0%, #ED6E1F 100%);
            // background: $ym-main-color;
            color: #FFFFFF;
            box-shadow: 0px 2px 6px 1px #C8C8C8;
        }
    }

    .desc {
        height: 100%;
        flex-grow: 1;


        .content-box {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .content-card {
            width: 100%;
            height: 100%;
            overflow: hidden;
            opacity: 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 7.5rem 5rem;
            transition: opacity .3s ease-in-out;
            visibility: hidden;

            img {
                height: 64%;
            }

            p {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.current {
                opacity: 1;
                visibility:visible;
            }
        }
    }
}
</style>