<template>
    <DescTemp :middle="true" title="行业方案">
        <div class="my-swiper">
            <div class="prevbtn" @click="swiper.slidePrev()"></div>
            <Swiper ref="mySwiper" :options="swiperOptions">
                <SwiperSlide v-for="(item, i) in cardlist" :key="i">
                    <div class="flex-box-item" :class="'item-' + i" @click="go($event, item.to)">
                        <div class="masklayer"></div>
                        <IconTitleText :inline="false" :icon="'icon' + i" v-bind="item">
                        </IconTitleText>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="nextbtn" @click="swiper.slideNext()"></div>
        </div>
    </DescTemp>
</template>

<script>
import DescTemp from "@/components/DescTemp.vue"
import TitleText from '@/components/TitleText.vue'
import IconTitleText from "@/components/IconTitleText.vue"
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
    components: {
        DescTemp,
        TitleText,
        IconTitleText,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            cardlist: [
                {
                    ptitle: "普货运输",
                    ptext: "帮助物流企业整合业务资源与运力资源，与运输上下游进行高效的协同工作，提供丰富的场景化运输解决方案",
                    to: { name: "Carrier" },
                },
                {
                    ptitle: "工厂/贸易",
                    ptext: "帮助货主企业规范运输管理，协同生产、经销与运输管控，优化物流成本，数字化管理承运商",
                    to: { name: "ManufacturingAndTrade" }
                },
                {
                    ptitle: "商砼运输",
                    ptext: "提供生产运输一体化协作管理，帮助商砼企业实现产业链协同数字化管理，打造智慧砼站",
                    to: { name: "Concrete" }
                },
                {
                    ptitle: "危化运输",
                    ptext: "提供涵盖运输管理与安全管理的数字化平台，提高运输效率与安全管控能力",
                    to: { name: "HC" }
                },
                {
                    ptitle: "生鲜冷链",
                    ptext: "为冷运企业提供精细化数字仓配一体管理，实现生鲜冷链运输透明化、数字化、互联化管理",
                    to: { name: "Coldchain" }
                },
                {
                    ptitle: "家具运输",
                    ptext: "为家居供应链物流提供全流程一体化技术解决方案，解决销售、运输与送装各环节之间信息互通与流程协作问题",
                    to: { name: "Delivery" }
                }
            ],
            swiperOptions: {
                slidesPerView: 5,
                spaceBetween: 20,
                speed: 800,
                autoplay: {
                    disableOnInteraction: false
                },
                loop: true,
                breakpoints: {
                    1366: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 12,
                    },
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 8,
                    },
                },
                on: {
                    init() {
                        this.slides.on('mouseenter', (e) => {
                            this.autoplay.stop()
                            /**
                             * @type {HTMLElement}
                             */
                            const dom = this.slides.eq(this.activeIndex)[0]
                            dom.className = dom.className + "userhover"
                            console.log()

                        }).on('mouseleave', () => {
                            this.autoplay.start()
                            const dom = this.slides.eq(this.activeIndex)[0]
                            setTimeout(() => {
                                dom.className = dom.className.replace("userhover", "").trim()
                            }, 100)
                        })

                        setTimeout(() => {
                            this.update()
                        }, 100)
                    }
                }
            },

        }
    },
    methods: {
        go(e, to) {
            this.$router.push(to)
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiperInstance
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/sass/mixin";

$maps: (
    "普货运输",
    "工厂贸易",
    "商砼运输",
    "危化运输",
    "生鲜冷链",
    "家具运输"
);

::v-deep.my-swiper {


    .swiper-container {
        width: calc(100% - 100px);
    }

    .flex-box-item {
        height: 22rem;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        transition: color .3s ease-in-out;
        background-color: #f5f5f5;
        border: 1px solid #D1D1D1;
        user-select: none;
        cursor: pointer;

        &.ishide {
            flex-basis: 0;
            opacity: 0;
            margin-right: 0;
        }

        .masklayer {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        .icon-title-text {
            width: 100%;
            height: 100%;
            padding: 2em;
            position: absolute;

            @include icon-title-text(4rem, 3em, 1.5rem, 0.875rem, 0.5em);

            .title-text .title {
                color: #F66E2C;
            }
        }
    }

    .swiper-slide {

        &.swiper-slide-active,
        &:hover {
            .flex-box-item {
                border: none;

                .masklayer {
                    background-color: rgba(#F66E2C, 0.25)
                }

                .icon-title-text {

                    .title,
                    .text {
                        color: #fff;
                    }
                }
            }
        }


        $i: 0;

        @each $name in $maps {
            .icon#{$i} {
                background-image: url("~@/assets/images/home/section4/" +$name + ".png");
            }

            &:hover,
            &.swiper-slide-active:not(.userhover) {
                .icon#{$i} {
                    background-image: url("~@/assets/images/home/section4/" +$name + "active.png");
                }

                .item-#{$i} {
                    background-image: url("./img/" + $name +".png");
                    background-size: cover;
                }
            }

            $i: $i+1;
        }

    }
}
</style>