

export const breakpoint = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1366
}

export const mixin = [{
    data() {
        return {
            clientWidth: document.documentElement.clientWidth,
            breakpointOff: null
        }
    },
    created() {
        this.breakpointOff = () => {
            this.calcClientWidth()
        }
        window.addEventListener("resize", this.breakpointOff)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.breakpointOff)
    },
    methods: {
        calcClientWidth() {
            this.clientWidth = document.documentElement.clientWidth
        }
    },
}]