<template>
    <div class="page-cloud-os">
        <Banner v-bind="banner">
            <template slot="btn">
                <div class="cyxq-box">
                    <button class="ym-btn-3" @click="callus()">咨询专家 &rarr;</button>
                    <button class="ym-btn-3" @click="startUse()">开始使用 &rarr;</button>
                </div>
            </template>
        </Banner>
        <article style="overflow:hidden;">
            <Section2></Section2>
            <Section3></Section3>
            <Section4></Section4>
        </article>
    </div>

</template>

<script>
import Section2 from './section2.vue';
import Section3 from './section3.vue';
import Section4 from './section4.vue';
import Banner from '@/components/Banner.vue';

export default {
    components: { Section2, Section3, Section4, Banner },
    data() {
        return {
            banner: {
                title: "运脉云OS",
                text: "运脉云OS专业面向物流行业，广泛适用于物流运输企业、生产商贸企业、\n"
                    + "客运行业、物流园区、行业协会等，为云应用软件提供统一标准的接口，"
                    + "\n支持轻应用开发与IoT接入，帮助用户获得高效、协同的全新物流管理方\n"
                    + "式，链接数字化物流生态",
                background: "linear-gradient(90deg, #E4E9FB 0%, #F3F6FD 20%, #D1D8F7 100%)",
                illustration: {
                    backgroundImage: `url(${require("@/assets/images/banners/运脉云OS.png")})`,
                },
                fontColorTheme: "main"
            },
        }
    }
}
</script>