<template>
  <header class="header">
    <div class="container-md container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <router-link to="/" class="navbar-brand">
          <img width="80px" src="@/assets/images/logo.png" alt="logo">
        </router-link>
        <!-- 导航栏菜单具体内容 -->
        <div class="collapse navbar-collapse show all-menu-box" id="navbarSupportedContent"
          @click="navMenuClick($event)">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="active" :to="{ name: 'home' }">首页</router-link>
            </li>
            <li class="nav-item product-menu-link" @mouseenter="productPanel = true" @mouseleave="productPanel = false"
              @click.stop="productClick($event)">
              <span class="nav-link" :class="{ active: $route.meta.parent === 'product' }">产品</span>
              <!-- 小窗口时展示的菜单信息 -->
              <ul class="collapse level1 product-menu-box show" v-if="!panelRender" @click.stop="navMenuClick($event)">
                <li class="nav-item" v-for="prod in productMenuList" :key="prod.title">
                  <router-link v-if="prod.to" class="nav-link" exact-active-class="active" :to="prod.to">
                    {{ prod.title }}
                  </router-link>
                  <span class="nav-link" v-else @click.stop="versionClick($event)">{{ prod.title }}</span>
                  <ul v-if="prod.children" class="collapse level2 version-menu-box show"
                    @click.stop="navMenuClick($event)">
                    <li class="nav-item" v-for="item in prod.children" :key="item.title">
                      <router-link class="nav-link" exact-active-class="active" :to="item.to">
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" active-class="active" :to="{ name: 'AppCenter' }">应用中心
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" active-class="active" :to="{ name: 'ChannelCoop' }">渠道合作
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" active-class="active" :to="{ name: 'EcologicalCoop' }">生态合作
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" active-class="active" :to="{ name: 'DevCoop' }">开发合作
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" active-class="active" :to="{ name: 'AboutYM' }">关于运脉
              </router-link>
            </li>
            <ProductMenu v-model="productPanel" v-if="panelRender" />
          </ul>
        </div>
        <div class="navbar-text">
          <span id="downloadapp" class="d-flex align-items-center" @mouseenter="downloadPanel = true"
            @mouseleave="downloadPanel = false" @click="downloadPanel = !downloadPanel">
            <i class="icon-app-phone"></i>
            <a>下载</a>
          </span>
          <span class="d-flex align-items-center">
            <i class="icon-web-login"></i>
            <a href="https://mp.yunmai56.com/sbt/#/login" target="_blank">登录</a>
          </span>
          <DownLoad v-model="downloadPanel" />
        </div>

        <!-- 小菜单按钮 -->
        <button @click="navMenuClick($event)" class="navbar-toggler" type="button"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav>
    </div>
  </header>
</template>

<script>
import ProductMenu from './ProductMenu'
import DownLoad from './DownLoad'
export default {
  data() {
    return {
      productPanel: false,
      downloadPanel: false,
      panelRender: true,
      off: null,
      navMenuShow: false,
      productMenuShow: false,
      versionMenuShow: false,
      productStyle: {
        height: 0
      },
      versionStyle: {
        height: 0
      },
      productMenuList: [
        {
          title: "运脉云OS",
          to: { name: 'CloudOS' },
        },
        {
          title: "云司机",
          to: { name: 'CloudDriver' },
        },
        {
          title: "IoT物联",
          to: { name: 'IoTConnect' },
        },
        {
          title: "行业平台",
          children: [
            {
              title: "物流承运商平台",
              to: { name: "Carrier" }
            },
            {
              title: "制造与商贸企业平台",
              to: { name: "ManufacturingAndTrade" }
            },
            {
              title: "商砼运输平台",
              to: { name: "Concrete" }
            },
            {
              title: "危化运输平台",
              to: { name: "HC" }
            },
            {
              title: "冷链运输平台",
              to: { name: "Coldchain" }
            },
            {
              title: "家具配送平台",
              to: { name: "Delivery" }
            }
          ]
        }
      ]
    }
  },
  methods: {
    navMenuClick(e) {
      if (this.panelRender) {
        return
      }
      const menuEle = document.getElementById("navbarSupportedContent")
      const pElem = document.getElementById("navbarSupportedContent").getElementsByClassName("product-menu-box").item(0)
      const vElem = document.getElementById("navbarSupportedContent").getElementsByClassName("version-menu-box").item(0)

      this.navMenuShow = !this.navMenuShow
      if (this.navMenuShow === false) {
        this.productMenuShow = false
        this.versionMenuShow = false
        menuEle.style.height = menuEle.scrollHeight + "px"
        setTimeout(v => {
          menuEle.style.height = ""
          pElem.style.height = ""
          vElem.style.height = ""
        }, 10)

      } else {
        menuEle.style.height = menuEle.scrollHeight + "px"
        console.log(menuEle.style.height, menuEle.scrollHeight)
      }
    },

    productClick(e) {
      const menuEle = document.getElementById("navbarSupportedContent")
      const pElem = document.getElementById("navbarSupportedContent").getElementsByClassName("product-menu-box").item(0)
      if (!pElem) {
        return
      }
      this.productMenuShow = !this.productMenuShow
      if (this.productMenuShow) {
        this.versionMenuShow = false
        pElem.style.height = pElem.scrollHeight + "px"
        menuEle.style.height = "auto"
      } else {
        pElem.style.height = pElem.scrollHeight + "px"
        setTimeout(v => {
          pElem.style.height = ""
        }, 10)
      }
    },

    versionClick(e) {
      const menuEle = document.getElementById("navbarSupportedContent")
      const pElem = document.getElementById("navbarSupportedContent").getElementsByClassName("product-menu-box").item(0)
      const vElem = document.getElementById("navbarSupportedContent").getElementsByClassName("version-menu-box").item(0)
      if (!pElem) {
        return
      }
      this.versionMenuShow = !this.versionMenuShow
      if (this.versionMenuShow) {
        pElem.style.height = "auto"
        vElem.style.height = vElem?.scrollHeight + "px"
      } else {
        vElem.style.height = ""
      }
    },
  },
  components: { ProductMenu, DownLoad },
  created() {
    const resize = () => {
      if (this.navMenuShow === true) {
        this.navMenuClick()
      }
      if (document.documentElement.clientWidth <= 992) {
        this.panelRender = false
        this.productPanel = false
        this.downloadPanel = false
      } else {
        this.panelRender = true
      }
    }
    this.$nextTick(() => {
      resize()
      window.addEventListener('resize', resize, false)
      this.off = () => {
        window.removeEventListener('resize', resize, false)
      }
    })
  },
  beforeDestroy() {
    this.off && this.off()
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/util";

.header {
  position: fixed;
  background: #fff;
  height: 60px;
  top: 0;
  z-index: 999;
  width: 100%;
}

.navbar-light {
  .navbar-text {
    display: flex;

    a {
      color: $ym-main-color;

      &:hover {
        color: $ym-main-color;
      }
    }

  }
}

.product-menu-box,
.version-menu-box {
  overflow: hidden;
  height: 0;
  transition: height .3s ease-in-out;
}


.icon-app-phone {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(~@/assets/images/header/下载.png);
  background-position: center;
  margin-right: 10px;
}

.icon-web-login {
  @extend .icon-app-phone;
  background-image: url(~@/assets/images/header/登陆.png);
  margin-left: 30px;
}


@include media-breakpoint-down(lg) {
  .navbar-collapse {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    background-color: #fefefe;
    padding: 0 2em;
    height: 0;
    overflow: hidden;
    transition: height .3s ease-in-out;


    .nav-item {}
  }

  .level1 {
    padding-left: 2em;
  }

  .level2 {
    padding-left: 2em;
  }
}

.navbar-nav {
  .nav-item {
    padding: 0 0.5rem;
    font-size: 16px;
  }
}
</style>