<template>
    <div class="title-text">
        <h2 class="title">{{ ptitle }}</h2>
        <p class="text" v-for="(p, i) in plist" :key="i">{{ p }}</p>
    </div>
</template>

<script>

export const titleTextProps = {
    ptitle: {
        type: String,
        default: ""
    },
    ptext: {
        type: [String, Array],
        default: ""
    }
}

export default {
    props: titleTextProps,
    created() {
        this.plist = this.plist.concat(this.ptext)
    },
    updated() {
        this.plist = this.plist.concat(this.ptext)
    },
    data() {
        return {
            plist: []
        }
    }
}
</script>

<style>
</style>