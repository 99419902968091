<template>
    <div class="footer-banner type1">
        <div class="mask">
            <div class="coop" v-if="$route.meta.footerBanner">
                <h1 :class="$route.meta.footerBanner.theme">{{ $route.meta.footerBanner.title }}</h1>
                <div class="btns-box" v-if="$route.meta.footerBanner.btns">
                    <button v-for="btn in $route.meta.footerBanner.btns" :class="btn.theme" @click="btn.click()">
                        {{ btn.text }}
                    </button>
                </div>
            </div>
            <div class="coop" v-else>
                <h1>联系我们，或立刻体验高效互联的数字物流</h1>
                <div>
                    <button class="btn1" @click="startUse()">开始使用</button>
                    <button class="btn2" @click="callus()">咨询专家</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss">
.footer-banner {
    text-align: center;
    color: #FFFFFF;

    height: 16em;

    .mask {
        background-color: rgba(#000000, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .coop{
        padding: 0 2em;
    }

    &.type1 {
        background-image: url(./img/type1.png);
        background-repeat:no-repeat ;
        background-size: cover;
    }

    h1 {
        font-size: 2.25rem;
        font-weight: 500;
        margin-bottom: 1em;
    }

    .btn1,
    .btn2 {
        padding: 0.7em 2.8em;
        border-radius: 8px;
        color: inherit;
        font-size: 1.25rem;
        font-weight: 500;
        cursor: pointer;
    }

    .btn1 {
        background: #F66E2C;
        margin-right: 2em;

    }

    .btn2 {
        border: 1px solid #FFFFFF;
        background: transparent;
    }
}
</style>