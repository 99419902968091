/**
 * @desc 平台路由及内容配置
 * 路由信息:
 * 1.物流承运商平台 Carrier
 * 2.制造与商贸企业平台 ManufacturingAndTrade
 * 3.商砼运输平台 Concrete
 * 4.危化运输平台 HC
 * 5.冷链运输平台 Coldchain
 * 6.家具配送平台 Delivery
 * 
 * 引用的图片资源也都在assets/product下
 */

import { startUse } from "@/utils/funcbtn"

export const platformList = [
    // 特殊四种
    {
        id: "page-concrete",
        name: "Concrete",
        path: "concrete",
        banner: {
            title: "商砼运输平台",
            text: "针对商砼运输行业特性，提供生产运输一体化协作\n管理，帮助商砼企业实现生产调度、企业管理、安\n全管理、上下游协同等数字化管理，并对接各种车\n辆及场站IoT，打造智慧砼站",
            btnlist: [],
            isreserver: true,
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "core-issues",
                    title: "核心问题",
                    arr: ["工作方式传统\n无协同能力", "依赖人工调度\n工作效率低", "各个系统独立\n数据不互联"].map((v, i) => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/mainIssues/商砼运输平台${i + 1}.png`)
                        }
                    })
                },
                {
                    id: "scheme-architecture",
                    title: "方案架构",
                    arr: [require(`@/assets/images/product/frame/商砼运输.png`)]
                },
                {
                    id: "scheme-advantages",
                    title: "方案优势",
                    layoutclass: "col-lg-4 col-md-6 col-12",
                    arr: [
                        {
                            title: "生产调度一体，全流程在线管理",
                            text: "植入核心调度算法，与ERP无缝对接，快速完成车辆调度，简化调度工作"
                        },
                        {
                            title: "与司机同步，装卸排队更有序",
                            text: "通过云司机APP向司机发送任务单及排队叫号信息，安排工作更灵活自由"
                        },
                        {
                            title: "订单在线化，结算准确方便",
                            text: "生产与运输订单关联并保存在云端，一键生成结算清单，对账简单有依据"
                        },
                        {
                            title: "跨系统协同，实现一站式应用",
                            text: "将调度、生产、结算、内部OA、通讯沟通等多系统集成在同一平台，以AI工作台的形式方便使用"
                        },
                        {
                            title: "集成全站IoT，打造智慧砼站",
                            text: "对接车辆IoT、站内IoT、生产线IoT等，帮助企业升级为智慧无人化管理模式"
                        },
                        {
                            title: "强化安全教育，规范安全生产",
                            text: "为企业车队长、安全员、司机提供相对应的安全教育课程，帮助企业建立完善安全生产规章制度"
                        }
                    ]
                }
            ]
        }
    },
    {
        id: "page-hc",
        name: "HC",
        path: "hc",
        banner: {
            title: "危化运输平台",
            text: "为危化品运输行业提供涵盖运输管理与安全管理的\n数字化管理平台，提高运输效率与安全管控能力，\n助力行业安全高效发展",
            btnlist: [],
            isreserver: true,
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "core-issues",
                    title: "核心问题",
                    arr: ["危化运输公司\n管理混乱", "危化品物流信息\n不互联互通", "缺乏动态风险\n评估体系"].map((v, i) => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/mainIssues/危化运输平台${i + 1}.png`)
                        }
                    })
                },
                {
                    id: "scheme-architecture",
                    title: "方案架构",
                    arr: [require(`@/assets/images/product/frame/危化运输1.png`), require(`@/assets/images/product/frame/危化运输2.png`)]
                },
                {
                    id: "scheme-advantages",
                    layoutclass: "col-md-6 col-12",
                    title: "方案优势",
                    arr: [
                        {
                            title: "电子订单全链贯通，打通运输协同",
                            text: "集成ERP、WMS、TMS等多个系统，对接厂家、仓储方托运数据，对运输全程进行在线透明化管理，实现跨企业高效协作，提升效率"
                        },
                        {
                            title: "人车企货多码合一，数字赋能管理",
                            text: "实现“红黄绿”安全码管理，人员码保障人员安全承运，车辆码保障车辆符合专业标准，企业码保障企业合规运营，货物码支持危化品全程追溯"
                        },
                        {
                            title: "人车安全征信审核，运力规范管理",
                            text: "实现对危险品运输相关数据的审核管理功能，核验运力资质与信用考核，保证运输人员与车辆的合法合规，降低运输风险"
                        },
                        {
                            title: "IoT智能设备互联，保障全程安全",
                            text: "结合IoT与AI算法，建立风险模型，实时监控驾驶行为及车辆运行数据，协同路网数据，对托运、承运及装卸全程进行在线管理"
                        },
                        {
                            title: "承运人员全程协同，异常及时处理",
                            text: "司机在线接单、上报运输节点，APP帮助司机规划安全合理的运输路线，实现装卸园区内导航，并提供一键报警、异常实时上报等异常情况协同处理功能"
                        },
                        {
                            title: "强化人员安全教育，完善安全制度",
                            text: "为企业负责人、司机、押运员等提供相对应的安全教育课程，帮助企业建立完善危化品安全生产规章制度及人员操作规程"
                        }
                    ]
                }
            ]
        }
    },
    {
        id: "page-coldchain",
        name: "Coldchain",
        path: "coldchain",
        banner: {
            title: "冷链运输平台",
            text: "为冷运企业提供精细化数字仓配一体管理，实现生\n鲜冷链运输透明化、数字化、互联化管理，帮助企\n业提高冷链运输运营能力",
            btnlist: [],
            isreserver: true,
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "core-issues",
                    title: "核心问题",
                    arr: ["运输过程不规范\n货物流通损耗率大", "信息化程度不足\n冷运断链时有发生", "运送全链环节多\n缺少互联协同方式"].map((v, i) => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/mainIssues/冷链运输平台${i + 1}.png`)
                        }
                    })
                },
                {
                    id: "scheme-architecture",
                    title: "方案架构",
                    arr: [require(`@/assets/images/product/frame/冷链运输.png`)]
                },
                {
                    id: "scheme-advantages",
                    layoutclass: "col-md-6 col-12",
                    title: "方案优势",
                    arr: [
                        {
                            title: "订单统一化，多渠道订单归集处理",
                            text: "可对接多种业态对应的ERP系统，将多渠道订单统一归集在同一平台处理，为不同货主定制个性化面单，满足多货主、多货品、多批次作业"
                        },
                        {
                            title: "仓配在线化，从仓到末端全程追溯",
                            text: "协同供应链多端，打通数据互联，实现从订单分配、分拣配载、在途运输到货物签收全流程的实时透明可视"
                        },
                        {
                            title: "运力数字化，建立企业动态运力池",
                            text: "支持自有运力、合同运力及外协运力混合管理，依据运输峰谷需求动态调控运力资源，提高运输效率"
                        },
                        {
                            title: "IoT智能化，全程精准监控温区变化",
                            text: "链接货物IoT、车载IoT、仓库IoT等各种场景下的物联传感设备，帮助相关人员实时了解库存及商品情况，设立动态预警机制"
                        },
                        {
                            title: "业财一体化，更灵活完善的费用结算",
                            text: "以电子订单为基础，自动归集与核对各类账务数据，实现业务数据和财务数据实时互联流转，依据内外部不同结算规则可灵活设置结算方式"
                        },
                        {
                            title: "客户精细化，精准匹配个性化要求",
                            text: "建立客户档案，导入合同项目管理，配送工作精准匹配不同货主的个性要求和发货规则，安排运输任务更有计划"
                        }
                    ]
                }
            ]
        }
    },
    {
        id: "page-delivery",
        name: "Delivery",
        path: "delivery",
        banner: {
            title: "家具配送平台",
            text: "为家居供应链物流提供全流程一体化技术解决\n方案，解决销售、运输与送装各环节之间信息\n互通与流程协作问题，提高家具运输管理时效\n性、安全性与透明化",
            btnlist: [],
            isreserver: true,
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "core-issues",
                    title: "核心问题",
                    arr: ["信息化程度低\n企业内外协同能力不足", "外包中转环节多\n过程追溯难度大", "末端送装服务把控难\n服务考核无依据"].map((v, i) => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/mainIssues/家具配送平台${i + 1}.png`)
                        }
                    })
                },
                {
                    id: "scheme-architecture",
                    title: "方案架构",
                    arr: [require(`@/assets/images/product/frame/家具配送.png`)]
                },
                {
                    id: "scheme-advantages",
                    layoutclass: "col-lg-4 col-md-6 col-12",
                    title: "方案优势",
                    arr: [
                        {
                            title: "集成互联，供应链上下游协同",
                            text: "对接生产、销售、仓储、运输、送装等各合作方，加强企业内部与跨企业之间的互联协作能力，提高整体效率"
                        },
                        {
                            title: "一单到底，送装全流程统一管理",
                            text: "电子订单关联出货单、配载单、签收单及末端服务单等，生产、运输及售后统一在线化标准管理"
                        },
                        {
                            title: "场景适配，灵活应用于不同模式",
                            text: "支持与第三方系统对接，提供定制化服务，适配送装一体、售后维修等多种家居供应链服务模式"
                        }
                    ]
                }
            ]
        }
    },
    // 另外两种
    {
        id: "page-Carrier",
        name: "Carrier",
        path: "carrier",
        meta: {
            footerBanner: {
                title: "立刻体验高效互联的智慧物流",
                btns: [{
                    text: "开始使用",
                    click: startUse,
                    theme: "btn2"
                }]
            }
        },
        banner: {
            title: "物流承运商平台",
            text: "整合业务资源与运力资源，与运输上下游进行高效的协同\n工作，帮助企业解决业务、运力、人员、车务、财务等多\n方面管理难题，提供丰富的场景化运输解决方案",
            btnlist: [],
            isreserver: true,
            background: "#FDFDFD"
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "suitable-industry",
                    title: "适用行业",
                    arr: ["干线运输", "零担运输", "大宗商品运输", "城市配送", "危化品运输", "冷链运输", "混凝土运输", "渣土运输"].map(v => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/industry/${v}.png`)
                        }
                    })
                },
                {
                    id: "main-function",
                    title: "主要功能",
                    arr: [
                        {
                            subTitle: "业务互联",
                            title: "企业间电子订单流转",
                            text: "在线接单 / 运价管理 / 业务路由 / 自定义面单"
                        },
                        {
                            subTitle: "L-TMS",
                            title: "上下游任务高效协同",
                            text: "运输管理 / IoT监控 / 在线签收 / 网点管理"
                        },
                        {
                            subTitle: "运力协同",
                            title: "内外部运力混合管理",
                            text: "运力筛选 / 运力招募 / 运力看板"
                        },
                        {
                            subTitle: "车务管理",
                            title: "全周期车辆精细管理",
                            text: "车辆档案 / 保险管理 / 维修保养 / 油耗电耗"
                        },
                        {
                            subTitle: "司机画像",
                            title: "司机安全与效率管理",
                            text: "司机画像 / 准驾管理 / 安全教育 / 司机招聘"
                        },
                        {
                            subTitle: "客户在线",
                            title: "数字化管理客户档案",
                            text: "客户管理 / 承运商管理 / 服务商管理"
                        },
                        {
                            subTitle: "智能账务",
                            title: "结算报销在线化处理",
                            text: "在线结算 / 流水台账 / 成本分析 /  统计报表"
                        },
                        {
                            subTitle: "数智管理",
                            title: "企业跨部门在线协同",
                            text: "OA审批 / 组织权限 / 公告管理 / 运信IM"
                        },
                        {
                            subTitle: "专业方案",
                            title: "细分场景化解决方案",
                            text: "行业专属功能适配各种应用场景"
                        },
                    ].map(v => {
                        return {
                            ...v,
                            imgUrl: require(`@/assets/images/product/icons/func/${v.subTitle}.png`),
                        }
                    })
                },
                {
                    id: "suitable-version",
                    title: "选择适合您的版本",
                    free: {
                        text: "适用于小微物流企业",
                        menus: ["AI工作台", "订单管理", "标准L-TMS", "司机管理", "车务管理", "财务管理", "CRM管理", "基础OA"]
                    },
                    professional: {
                        text: "深度管理及专业行业应用",
                        menus: ["专业L-TMS", "智慧车联", "安全教育", "司机画像", "OA审批", "API对接", "功能定制"]
                    }
                }
            ]
        }
    },
    {
        id: "page-manufacturing-and-trade",
        name: "ManufacturingAndTrade",
        path: "manufacturingandtrade",
        banner: {
            title: "制造与商贸企业平台",
            text: "为生产制造及商贸企业提供承运伙伴社群化流程管理，规范运\n输管理，协同生产经营管控，优化物流成本投入，加强对承运\n商的整体化管理",
            btnlist: [],
            isreserver: true,
            background: "#FDFDFD"
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "suitable-industry",
                    title: "适用行业",
                    arr: ["制造企业", "商贸企业", "电商平台", "城市配送", "个体货主"].map(v => {
                        return {
                            text: v,
                            imgUrl: require(`@/assets/images/product/icons/industry/${v}.png`)
                        }
                    })
                },
                {
                    id: "main-function",
                    title: "主要功能",
                    arr: [
                        {
                            subTitle: "发货管理",
                            title: "从源头监管运输过程",
                            text: "节点跟踪 / 实时轨迹 / 异常举证 / 签收管理"
                        },
                        {
                            subTitle: "社群管理",
                            title: "承运伙伴社群化协同",
                            text: "自动路由下单 / 承运商管理 / 询价管理 / 预约请车"
                        },
                        {
                            subTitle: "智能账务",
                            title: "简化账务并优化成本",
                            text: "在线对账 / 统计报表 / 成本分析"
                        },
                        {
                            subTitle: "OA审批",
                            title: "关联OA单据审批",
                            text: "下单审批 / 用车审批 / 发车审批 / 结算审批"
                        },
                        {
                            subTitle: "IoT互联",
                            title: "智能设备连接与监控",
                            text: "车辆定位 / 货物定位 / 场站IoT / 仓库IoT"
                        },
                        {
                            subTitle: "生产对接",
                            title: "生产运输一体化管理",
                            text: "工控对接 / OMS对接 / ERP对接"
                        },
                    ].map(v => {
                        return {
                            ...v,
                            imgUrl: require(`@/assets/images/product/icons/func/${v.subTitle}.png`),
                        }
                    })
                },
                {
                    id: "scheme-architecture",
                    title: "方案架构",
                    arr: [require(`@/assets/images/product/frame/制造与商贸企业.png`)]
                },
                {
                    id: "scheme-advantages",
                    layoutclass: "col-lg-4 col-md-6 col-12",
                    title: "方案优势",
                    arr: [
                        {
                            title: "上下游透明化运输互联",
                            text: "帮助货主企业打通与承运商、司机的连接，实现下单—接单—运输—签收全流程在线化协作，结合IoT物联设备对货物状态与位置全程监控，运输纠纷有依据"
                        },
                        {
                            title: "实时在线跟踪订单进度",
                            text: "通过运输看板实时查看任务进程，全程在线跟踪运输过程，节点主动推送，更易把控运输时效，合理安排后续生产经营活动"
                        },
                        {
                            title: "建立智能调度管控流程",
                            text: "关联OA审批，对发运任务进行流程化管理，建立规范的物流管理制度，并可预设业务路由机制，将审核后的订单按规则自动派发给对应承运商，提升协同效率"
                        },
                        {
                            title: "社群化管理运力伙伴",
                            text: "建立运力社群，将承运商伙伴加入社群统一数字化管理，实现在线沟通与记录留存，数据化呈现合作结果与服务能力，提供考核及结算依据"
                        },
                        {
                            title: "智能优化物流成本",
                            text: "运用AI算法，结合财务数据，对企业周期性物流费用支出进行智能分析，给出合理的物流方案建议，帮助企业及时了解并优化物流成本"
                        },
                        {
                            title: "提升内外部协同能力",
                            text: "对接生产、仓储与企业内部OA、财务等系统，以便捷的方式打造统一的工作平台，加强物流数字化综合管理能力"
                        }
                    ]
                },
                {
                    id: "suitable-version",
                    title: "选择适合您的版本",
                    free: {
                        text: "适用于小微物流企业",
                        menus: ["AI工作台", "标准订单管理", "CRM管理", "财务管理", "基础OA"]
                    },
                    professional: {
                        text: "深度管理及专业行业应用",
                        menus: ["专业订单管理", "IoT智联设备", "OA审批", "API对接", "功能定制"]
                    }
                }
            ]
        }
    },
].map(v => {
    v.banner.imgUrl = require(`@/assets/images/banners/${v.banner.title}.png`)
    v.body.list.map(v => {
        v.active = false
        return v
    })
    return v
})

export const platformChildren = platformList.map(v => {
    return {
        path: v.path,
        name: v.name,
        component: {
            render(h) {
                return h('platform-creator', {
                    props: {
                        banner: v.banner,
                        body: v.body,
                    },
                    class: v.id,
                })
            }
        },
        meta: {
            ...v.meta,
            parent: "product"
        }
    }
})