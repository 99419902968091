<template>
    <section class="section2">
        <div class="container">
            <ul class="sq-card-box">
                <li class="sq-card" v-for="(item, i) in section2List" :key="i">
                    <icon-title-text :icon="'icon' + i" :inline="false" :ptext="item.ptext" :ptitle="item.ptitle">
                    </icon-title-text>
                    <div class="extra-box">
                        <div class="icon">
                            <span :class="'icon-extra' + i"></span>
                        </div>
                        <div class="title-text">
                            <ul class="extra-list">
                                <li v-for="extraItem in item.extralist" :key="extraItem" class="extra-item">{{
                                        extraItem
                                }}</li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import IconTitleText from '@/components/IconTitleText.vue';

export default {
    components: {
        IconTitleText
    },
    data() {
        return {
            section2List: [
                {
                    ptitle: "小投入，大系统",
                    ptext: "运脉云提供基础技术平台，用户可在基础平台上自定义应用模式，以最少的成本，获取大企业级别数字化平台",
                    extralist: [
                        "为中小微企业提供简便、高效的数字化管理平台",
                        "可自由配置符合自己管理需求的个性化系统",
                        "节省研发与运维费用"
                    ]
                },
                {
                    ptitle: "多场景解决方案",
                    ptext: "针对细分物流领域，提供场景化专业解决方案，满足行业个性需求",
                    extralist: [
                        "针对细分运输类型的解决方案",
                        "针对仓储、园区、港口的解决方案",
                        "针对行业协会管理的解决方案"
                    ]
                },
                {
                    ptitle: "生态用户类型多样",
                    ptext: "运脉云为各种类型用户提供高效互联的协作方式，共同打造物流生态",
                    extralist: [
                        "承运商、生产制造、园区、仓储等企业用户",
                        "商用车司机、骑手、灵活就业者等个人用户",
                        "保险、金融、车后服务等增值服务商",
                        "行业协会等管理型用户"
                    ]
                },
                {
                    ptitle: "开放型技术平台",
                    ptext: "协同开发者伙伴，支持与各行业平台数据互联，共同打造场景化整体解决方案",
                    extralist: [
                        "提供标准API接口",
                        "轻应用开发框架",
                        "IoT硬件设备对接"
                    ]
                },
                {
                    ptitle: "社群化物流协同",
                    ptext: "帮助用户组建自己的物流社群，在社群内与自己的合作伙伴互联互通、协同工作",
                    extralist: [
                        "用户可创建以自己为中心的物流社群，也可申请加入其它社群",
                        "借助运脉云生态，拓展合作伙伴，获得更多资源",
                        "保障社群圈子的私密性，维护原有合作生态"
                    ]
                },
                {
                    ptitle: "上下游工作互联",
                    ptext: "打通信息流，快速、简便地与上下游建立高效协同的工作模式",
                    extralist: [
                        "通过订单互联，实现在线下单、在线接单、在途监管、在线签收等",
                        "通过数据互联，实现业务合作、财务结算、运力共享等协同工作",
                        "帮助企业与司机进行在线交流与工作协同"
                    ]
                },
                {
                    ptitle: "一站式工作桌面",
                    ptext: "支持对接其它系统数据统一呈现于AI工作台，无需切换多个系统也可完成工作",
                    extralist: [
                        "订单、车辆、司机、客户、财务、OA等内外部数据同平台呈现",
                        "待办工作提醒",
                        "简便、快捷的操作方式"
                    ]
                },
                {
                    ptitle: "数据安全保障",
                    ptext: "与阿里云、华为云等战略合作，保障用户的应用安全、数据安全、信息安全",
                    extralist: [
                        "多级数据加密，保障用户数据不泄露",
                        "云端备份与异地灾备，保障核心数据不丢失",
                        "通过相关部门安全检查，杜绝安全隐患"
                    ]
                }
            ]
        }
    }
}
</script>

<style lang="scss">
@import "@/sass/util";

.page-cloud-os {
    .section2 {
        padding-top: 9rem;

        .sq-card-box {
            display: flex;
            flex-wrap: wrap;
        }

        .sq-card {
            width: 300px;
            height: 300px;
            box-shadow: 0 0 0 1px #eceef2;
            position: relative;

            @include media-breakpoint-down(xl) {
                width: 240px;
                height: 260px;
            }

            .icon-title-text {
                padding: 1.5em;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fff;
                transition: all .5s ease-in-out;
                opacity: 1;
                @include icon-title-text(3rem, 1.2em, 1.5rem, 0.875rem, 1em);
                background-color: #fff;
            }

            .extra-box {
                @extend .icon-title-text;
                background: linear-gradient(135deg, #F7A742 0%, #ED7020 100%, #ED6E1F 100%);
                opacity: 0;

                .extra-item {
                    list-style-type: disc;
                    list-style-position: inside;
                    font-size: 0.875rem;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 1em;
                }
            }

            &:hover {
                .icon-title-text {
                    opacity: 0;
                }

                .extra-box {
                    opacity: 1;
                }
            }

            $i: 0;

            @each $name in ("小投入", "多场景", "生态", "开放", "社群化", "上下游", "一站式", "数据安全") {

                .icon#{$i} {
                    background-image: url("~@/assets/images/ymcloud/section2/" +$name+"未选.png");
                }

                .icon-extra#{$i} {
                    background-image: url("~@/assets/images/ymcloud/section2/" +$name+"已选.png");
                }


                $i: $i+1;
            }
        }
    }


}
</style>