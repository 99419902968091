<template>
    <div class="page-home">
        <Banner v-bind="banner">
            <template slot="btn">
                <router-link class="btn1" :to="{ name: 'CloudOS' }">
                    <IconTitleText :inline="true" icon="icon1" ptitle="运脉云OS" ptext="Yunmai OS"></IconTitleText>
                </router-link>
                <router-link class="btn2" :to="{ name: 'CloudDriver' }">
                    <IconTitleText :inline="true" icon="icon2" ptitle="云司机" ptext="Cloud Driver"></IconTitleText>
                </router-link>
            </template>
        </Banner>
        <article style="overflow:hidden;">
            <Section2></Section2>
            <Section3></Section3>
            <Section4></Section4>
            <Section5></Section5>
            <Section6></Section6>
        </article>
    </div>
</template>

<script>
import Section2 from './Section2.vue'
import Section4 from './Section4.vue'
import Section5 from './Section5.vue'
import Section6 from './Section6.vue'
import Section3 from './Section3.vue'
import Banner from '@/components/Banner.vue'
import IconTitleText from '@/components/IconTitleText.vue'

export default {
    name: 'HomeView',
    components: {
        Section2,
        Section4,
        Section5,
        Section6,
        Section3,
        Banner,
        IconTitleText
    },
    data() {
        return {
            banner: {
                prefixTitle: "运脉云",
                title: "数字物流技术平台",
                text: "为物流行业提供多场景数字化管理解决方案\n实现高效互联的物流工作方式",
                background: `url(${require("@/assets/images/banners/数字物流技术平台1.png")}) left center/80% 100% no-repeat,url(${require("@/assets/images/banners/数字物流技术平台.png")}) 114% center/55% 105% no-repeat,linear-gradient(#ff972d, #fe6000)`,
                // imgUrl:require("@/assets/images/banners/数字物流技术平台.png"),
                fontColorTheme: "dark",
            },
        }
    }
}
</script>

<style lang="scss">
@import "@/sass/util";

.page-home {
    .banner {
        padding-top: calc(48% - 60px);

        .container {
            .btn-box {
                display: flex;
                margin-top: 5em;
                justify-content: space-between;

                .icon-title-text {
                    @include icon-title-text(3rem, 1.25rem, 1.125rem, 0.875rem, 0);
                }

                %btnbase {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    width: calc(50% - 1em);
                    max-width: 240px;
                    padding: 1em 1em;

                    .title-text{
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }

                .btn1 {
                    @extend %btnbase;
                    background: linear-gradient(180deg, #FFAA54 0%, #EB680C 100%);
                    box-shadow: 0px 2px 4px 0px rgba(238, 116, 46, 0.6000), 0px 7px 10px 0px rgba(238, 116, 46, 0.5000), inset 0px -2px 2px 0px #FE5000, inset 0px 1px 2px 0px #FF9564;

                    .icon-title-text {
                        .text,
                        .title {
                            color: #FFFFFF;
                        }
                    }
                }

                .btn2 {
                    @extend %btnbase;
                    background: linear-gradient(46deg, #FFFFFF 0%, #F1F1F1 100%);
                    box-shadow: -1px 1px 2px 0px rgba(132, 132, 132, 0.5000), -2px 2px 1px 0px rgba(197, 197, 197, 0.4000), inset -2px 2px 1px 0px #FFFFFF, inset 0px 1px 3px 0px rgba(236, 236, 236, 0.8000);
                }

                .icon1 {
                    background-image: url(~@/assets/images/home/banner/运脉云OS.png);
                }

                .icon2 {
                    background-image: url(~@/assets/images/home/banner/云司机.png);
                }
            }
        }
    }
}
</style>
