import MainAsideVue from "./MainAside.vue"
import ProductCreator from "./ProductCreator.vue"
import PlatformCreator from "./PlatformCreator.vue"



export default {
    install(Vue) {
        Vue.component(MainAsideVue.name, MainAsideVue)
        Vue.component(ProductCreator.name, ProductCreator)
        Vue.component(PlatformCreator.name, PlatformCreator)
    }
}