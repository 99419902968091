<template>
    <footer class="page-home-footer">
        <FooterBanner></FooterBanner>
        <div class="footer">
            <div class="container footer-info flex-wrap flex-lg-nowrap">
                <div v-if="clientWidth > breakpoint.lg" class="footer-nav flex-lg-nowrap flex-sm-wrap">
                    <ul class="nav-list">
                        <li class="nav-list-item" v-for="group in navlist" :key="group.title">
                            <h1 class="h1">{{ group.title }}</h1>
                            <ul class="link-list">
                                <li class="link-list-item" v-for="link in group.list" :key="link.title">
                                    <router-link :to="link.to">{{ link.title }}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-else style="width:100%">
                    <ul class="menu">
                        <li class="menu-submenu" v-for="(group, index) in navlist" :key="group.title">
                            <h1 class="menu-submenu-title" @click="changeMenu($event, group, index, navlist)">
                                {{ group.title }}
                            </h1>
                            <ul class="menu-container" :style="{ height: group.height }">
                                <li class="menu-item" v-for="link in group.list" :key="link.title">
                                    <router-link :to="link.to">{{ link.title }}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="other-info">
                    <div class="ym_logo_box">
                        <img width="80" :src="require('@/assets/images/logo.png')" alt="logo">
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div style="flex-grow:1;">
                            <p class="list-item">
                                <img style="margin-right:8px" src="@/assets/images/footer/call.png"
                                    alt="全国服务热线：400-900-5256">
                                <span>全国服务热线：400-900-5256</span>
                            </p>
                            <address class="list-item">
                                <img style="margin-right:8px" src="@/assets/images/footer/address.png"
                                    alt="公司地址：浙江省杭州市西湖区东部软件园创新大厦B座5F">
                                <span>公司地址：浙江省杭州市西湖区东部软件园创新大厦B座5F</span>
                            </address>
                        </div>
                        <div class="follow_us">
                            <img width="105px" src="@/assets/images/footer/follow_us.png">
                            <p>关注我们</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container footer_ba_box">
                <div class="footer_ba">
                    <p>版权所有运脉云技术有限公司</p>
                    <p style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <a target="_blank"
                            href="http://beian.miit.gov.cn/">浙ICP备16002882号-2</a>&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证号：<a
                            target="_blank"
                            href="http://beian.miit.gov.cn/">浙B2-20160860</a>&nbsp;&nbsp;&nbsp;&nbsp;浙公网安备<a
                            target="_blank"
                            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602004826">33010602004826号</a>
                        <!-- <a target="_blank" href="http://beian.miit.gov.cn/">浙B2-20160860</a>&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证号：<a  target="_blank" href="http://beian.miit.gov.cn/">浙B2-20160860</a>&nbsp;&nbsp;&nbsp;&nbsp;浙公网安备 <a  target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602008448&time=1584350845000">33010602008448号</a> -->
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import FooterBanner from './FooterBanner'
import { mixin } from "@/utils/breakpoint"

export default {
    data() {
        return {
            navlist: [
                {
                    title: "了解运脉云",
                    height: 0,
                    list: [
                        {
                            title: "运脉云OS",
                            to: { name: 'CloudOS' }
                        },
                        {
                            title: "云司机",
                            to: { name: 'CloudDriver' }
                        },
                        {
                            title: "IoT物联",
                            to: { name: 'IoTConnect' }
                        },
                        {
                            title: "应用中心",
                            to: { name: 'AppCenter' }
                        }
                    ]
                },
                {
                    title: "行业平台",
                    height: 0,
                    list: [
                        {
                            title: "物流承运商平台",
                            to: { name: 'Carrier' }
                        },
                        {
                            title: "制造与商贸企业平台",
                            to: { name: 'ManufacturingAndTrade' }
                        },
                        {
                            title: "商砼运输平台",
                            to: { name: 'Concrete' }
                        },
                        {
                            title: "危化运输平台",
                            to: { name: 'HC' }
                        },
                        {
                            title: "冷链运输平台",
                            to: { name: 'Coldchain' }
                        },
                        {
                            title: "家具配送平台",
                            to: { name: 'Delivery' }
                        }
                    ]
                },
                {
                    title: "运脉云合作",
                    height: 0,
                    list: [
                        {
                            title: "渠道合作",
                            to: { name: 'ChannelCoop' }
                        },
                        {
                            title: "生态合作",
                            to: { name: 'EcologicalCoop' }
                        },
                        {
                            title: "开发合作",
                            to: { name: 'DevCoop' }
                        }
                    ]
                },
            ],
        }
    },
    components: { FooterBanner },
    mixins: mixin,
    methods: {
        changeMenu(e, item, index, self) {
            if (parseInt(item.height) > 0) {
                item.height = 0
            } else {
                for (let i = 0; i < self.length; i++) {
                    if (i !== index) {
                        self[i].height = 0
                        console.log(i, self[i].height)
                    }
                }
                item.height = e.target.nextSibling.scrollHeight + "px"
            }
        },
    }
}
</script>

<style lang="scss">
@import "@/sass/util";
$color1: #fff;
$color2: rgba($color1, 0.8);

.page-home-footer {
    .footer {
        background-color: #3e3e3e;
    }
}

.footer-info {
    padding: 3rem 0;
    display: flex;
    margin: auto;

    .footer-nav {
        width: 55%;
        color: $color1;
        padding-right: 5rem;
        border-right: 1px solid rgba(#fff, 0.3);

        .h1 {
            font-size: 1rem;
            margin-bottom: 1.5em;
            font-weight: 500;
        }


        .nav-list {
            display: flex;
            justify-content: space-between;

            & a {
                color: $color2;
                font-size: 0.875rem;
                font-weight: 400;
            }

            .link-list-item {
                margin-bottom: 0.8em;
            }
        }

        @include media-breakpoint-down(lg) {
            padding-right: 0;
        }
    }

    .other-info {
        flex-grow: 1;
        padding-left: 5rem;
        font-size: 0.875rem;
        color: $color1;

        .ym_logo_box {
            margin-bottom: 1em;
        }

        .list-item {
            display: flex;
            align-items: center;
            margin-bottom: 1em;
        }

        .follow_us {
            display: block;
            text-align: center;
            margin-bottom: 0;
            margin-left: 2em;
        }

        @include media-breakpoint-down(lg) {
            padding-right: 2em;
            padding-left: 2em;
        }
    }
}
</style>

<style lang="scss">
@import "@/sass/util";

.page-home-footer {
    .menu {
        color: #fff;
        padding: 2em 2em;

        .menu-container {
            padding-left: 2em;
            border-bottom: 1px solid #75777a;
            overflow: hidden;
            transition: height .3s ease-in-out;
        }

        .menu-submenu-title,
        .menu-item {
            font-size: 1.2rem;
            height: 2.5em;
            line-height: 2.5em;

            &:hover {
                color: $ym-main-color;
                cursor: pointer;
            }
        }

        .menu-submenu-title {
            border-bottom: 1px solid #75777a;
        }
    }
}
</style>
<style lang="scss">
// 底部备案信息
.footer_ba_box {
    text-align: center;
    color: #666;
    font-size: 12px;
    padding: 3rem 0;
}
</style>