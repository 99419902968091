<template>
    <div class="ymyst">
        <DescTemp :title="title">
            <div class="layout-main-aside flex-row-reverse">
                <div class="layout-aside-container text-center">
                    <img :src="require('@/assets/images/home/运脉云生态.png')">
                </div>
                <div class="layout-main-container">
                    <title-text :ptitle="ptitle" :ptext="ptext"></title-text>
                </div>
            </div>
        </DescTemp>
    </div>
</template>

<script>
import DescTemp from '@/components/DescTemp.vue';
import TitleText from '@/components/TitleText.vue';
import MainAside from '@/components/MainAside.vue';
export default {
    components: { DescTemp, TitleText, MainAside },
    data() {

        return {
            title: "运脉云生态",
            ptitle: "互联生态，全面赋能",
            ptext: "运脉云作为物流行业的基础大平台，协同物流企业、生产商贸企业、物流园区、行业协会、服务商伙伴及开发者伙伴等，融合IoT智能硬件使用场景，与各行业平台数据互联，打造物流数字生态"
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/sass/_mixin.scss";

.ymyst {

    .layout-main-aside {
        align-items: center;
    }

    ::v-deep .title-text {
        @include title-text(2.25rem, 1rem, 1em);
    }
}
</style>