<template>
    <div class="popup-download" :class="{ show: value }">
        <div class="bg" @mouseenter="me()" @mouseleave="mo()">
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div class="wly">
                        <div class="text-box">
                            <IconTitleText icon="mobile-logo" ptext="为物流行业提供专业的互联管理工具" ptitle="运脉云" :inline="false" />
                            <div class="btns">
                                <button class="btn-default" @mouseenter="show1 = true" @mouseleave="show1 = false">
                                    iOS下载
                                    <img class="qr-code" :class="{ show: show1 }"
                                        :src="require('./img/yunmai_code.png')" alt="">
                                </button>
                                <button class="btn-default" @mouseenter="show3 = true" @mouseleave="show3 = false">
                                    Android下载
                                    <img class="qr-code" :class="{ show: show3 }"
                                        :src="require('./img/yunmai_code.png')" alt="">
                                </button>
                            </div>
                        </div>
                        <div class="img-flex">
                            <img class="app-show" :src="require('./img/ymyapptemp.png')" alt="">

                        </div>
                    </div>
                    <div class="ysj">
                        <div class="text-box">
                            <IconTitleText class="right" icon="mobile-logo" ptext="司机工作与学习互联平台" ptitle="云司机"
                                :inline="false" />
                            <div class="btns">
                                <button class="btn-default" @mouseenter="show2 = true" @mouseleave="show2 = false">
                                    iOS下载
                                    <img class="qr-code" :class="{ show: show2 }"
                                        :src="require('./img/driver_code.png')" alt="">
                                </button>
                                <button class="btn-default" @mouseenter="show4 = true" @mouseleave="show4 = false">
                                    Android下载
                                    <img class="qr-code" :class="{ show: show4 }"
                                        :src="require('./img/driver_code.png')" alt="">
                                </button>
                            </div>
                        </div>
                        <div class="img-flex">
                            <img class="app-show" :src="require('./img/ysjapptemp.png')" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import IconTitleText from '@/components/IconTitleText.vue';
export default {
    emits: ["input"],
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false
        };
    },
    components: { IconTitleText },
    methods: {
        me() {
            this.setValue(true)
            this.$emit("input", true)
        },
        mo() {
            this.setValue(false)
            this.$emit("input", false)
        },

        setValue(value) {
            clearTimeout(document.body.$timer)
            document.body.$timer = setTimeout(() => {
                const body = document.body
                if (value) {
                    body.style.overflow = "hidden"
                } else {
                    body.style.overflow = ""
                }
            }, 500);
        }
    },
    updated() {
        this.setValue(this.value)
    }
}
</script>

<style lang="scss">
@import "@/sass/util.scss";

.popup-download {
    position: fixed;
    left: 0;
    top: 60px;
    height: 0;
    width: 100%;
    background: rgba(#000000, 0.5);
    overflow: hidden;
    transition: all 0.3s ease-in 0.15s;

    &.show {
        height: 100vh;
        overflow-y: auto;
    }

    .bg {
        background: #FFFFFF;
    }


    .container {
        padding: 1em;
        height: 100%;
        display: block;
    }

    %base {
        position: relative;
        margin-bottom: 100px;
        width: 19.2rem;

        .text-box {
            width: 100%;
        }

        .img-flex {
            position: absolute;
            top: 0;
            height: 8em;

            .app-show {
                height: 100%;
            }


        }

        .btns {
            display: flex;
            justify-content: space-between;

            .btn-default {
                background: #F66E2C;
                border-radius: 2em;
                padding: 0.6em 1.5em;
                font-size: 1rem;
                font-weight: 500;
                color: #FFFFFF;
                position: relative;

                &:first-child {
                    margin-right: 1em;
                }

                .qr-code {
                    position: absolute;
                    top: calc(100% + 4px);
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    transition: all .3s ease-in-out;

                    &.show {
                        height: 84px;
                        width: 84px;
                    }
                }
            }
        }

        .icon-title-text {
            @include icon-title-text(5rem, 1.5em, 1.5rem, 1.2rem, 0.5em);
            margin-bottom: 1.2em
        }
    }



    .wly {
        @extend %base;

        .mobile-logo {
            background-image: url(./img/ymyapplogo.png);
        }

        .img-flex {
            right: 0;

            .qr-code {
                right: -84px;
            }
        }
    }

    .ysj {
        @extend %base;
        flex-direction: row-reverse;
        text-align: right;

        .mobile-logo {
            background-image: url(./img/ysjapplogo.png);
        }

        .img-flex {
            left: 0;

            .qr-code {
                left: -84px;
            }
        }
    }
}
</style>