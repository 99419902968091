<template>
    <DescTemp title="IoT物联智链" :middle="true">
        <div class="sub-title">
            运脉云联合IoT智能硬件生态伙伴，打造软硬件一体的物流场景解决方案，链接人、车、货、仓、场、路，满足数字物流的全景化管理需求
        </div>
        <ul class="flex-box">
            <li class="flex-box-item iot-card" v-for="(item, i) in cardlist" :key="i" @click="go($event, item.to)">
                <IconTitleText :inline="false" :icon="'icon' + i" v-bind="item"></IconTitleText>
            </li>
        </ul>
    </DescTemp>
</template>

<script>
import DescTemp from "@/components/DescTemp.vue"
import IconTitleText from "@/components/IconTitleText.vue"
export default {
    components: {
        DescTemp,
        IconTitleText,
    },
    data() {
        return {
            cardlist: [{
                ptitle: "车辆IoT",
                ptext: "智能链接车辆，获取运行状态及识别驾驶行为",
                to: { name: "IoTConnect", hash: "cariot" }

            }, {
                ptitle: "园区IoT",
                ptext: "与园区智能硬件设备互联，打造智慧园区",
                to: { name: "IoTConnect", hash: "parkiot" }

            }, {
                ptitle: "仓库/厂区IoT",
                ptext: "智能互联生产设备与仓库IoT，升级智慧工业",
                to: { name: "IoTConnect", hash: "plantiot" }
            }]
        }
    },
    methods: {
        go(e, to) {
            this.$router.push(to)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/sass/util";

::v-deep .desc-card-body {
    padding-top: 0;
}

.sub-title {
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666;
    text-align: center;
    margin-bottom: 4em;
}

.flex-box {
    margin: auto;
    width: 90%;
    min-width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

$imgmaps: (
    0:"车辆IoT",
    1:"园区IoT",
    2:"仓库厂区IoT"
);

.desc-card {
    padding-bottom: 0;

    ::v-deep .desc-card-body {
        padding-bottom: 0;
    }
}

::v-deep .iot-card {
    width: 200px;
    cursor: pointer;
    padding: 1em;
    border-radius: 8px;
    transition: all .3s ease-in-out;

    &:hover {
        box-shadow: 0 0 0 1px $ym-main-color
    }

    .icon-title-text {
        @include icon-title-text(9.375rem, 0, 1.5rem, 0.875rem, 1em);
    }

    .icon {
       justify-content: center;
        @for $i from 0 through 2 {
            .icon#{$i} {
                background-image: url("~@/assets/images/home/section5/" +map-get($imgmaps, $i)+".png");
                background-repeat: no-repeat;
                background-size: 100%;
            }
        }
    }

    .title {
        text-align: center;
    }
    .text{
        text-align: justify;
    }

}
</style>