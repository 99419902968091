<template>
    <div class="product-common-page">
        <Banner v-bind="banner"></Banner>
        <div ref="subNavBar" class="sub-nav-bar d-sm-none d-md-block" :class="{ allin: subNavBarShowAll }"
            v-if="body.hasNav">
            <div class="container">
                <span class="h1">{{ banner.title }}</span>
                <span class="nav-link" v-for="item in body.list" :class="{ active: item.id === activeNavLink }"
                    @click="scroll(item.id)">{{ item.title }}</span>
                <button class="btn" @click="startUse()">立即使用</button>
            </div>
        </div>
        <article class="article">
            <section class="section" :id="item.id" v-for="item in body.list">
                <div class="container layout-main-aside" :class="{ 'flex-row-reverse': item.isreverse }">
                    <div class="layout-aside-container">
                        <img width="100%" :src="item.img">
                    </div>
                    <div class="layout-main-container">
                        <div class="title-text">
                            <h1 class="title" v-if="item.title">{{ item.title }}</h1>
                            <h2 class="suffix-title" v-if="item.suffixTitle">{{ item.suffixTitle }}</h2>
                            <p class="text" v-for="text in item.texts">{{ text }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="interest-you">
                <div class="container">
                    <div class="title">您可能还感兴趣：</div>
                    <ul class="list-box">
                        <li class="list-item col-lg-3 col-md-4 col-sm-6" v-for="item in productlist" @click="go(item)">
                            <div class="tab" :class="{ active: item.name === $route.name }">
                                <span>{{ item.banner.title }}</span>
                            </div>
                        </li>
                        <li class="list-item col-lg-3 col-md-4 col-sm-6 list-item-extra"></li>
                    </ul>
                </div>
            </section>
        </article>
    </div>
</template>

<script>
import TitleText from '@/components/TitleText.vue';
import { productlist } from '@/router/product';
import Banner from './Banner.vue';
import { PPCreatorMixin } from './mixin';


export default {
    components: { TitleText, Banner },
    name: "product-creator",
    props: {
        banner: {
            type: Object,
            require: true
        },
        body: {
            type: Object,
            require: true
        },
    },
    data() {
        return {
            productlist
        }
    },
    methods: {
        go(item) {
            if (item.name === this.$route.name) {
                return
            } else {
                this.$router.push({ name: item.name })
            }
        },
    },
    mixins: [PPCreatorMixin]
}
</script>

<style lang="scss" >
@import "@/sass/util";


.product-common-page {

    // 文章区域
    .article {
        .section {
            padding: 9rem 0;

            &:nth-child(2n) {
                background: #F5F7FB;
            }

            .title-text {
                @include title-text(2rem, 1rem, 1.5em);

                .suffix-title {
                    font-size: 1.5rem;
                    margin-bottom: 1em;
                }

                .text {
                    display: list-item;
                    list-style-type: disc;
                    white-space: pre-wrap;
                    line-height: 1.5em;
                    list-style-position: outside;
                    margin-left: 1em;


                    &::marker {
                        color: #EE742E;
                        font-size: 16px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .interest-you {
            padding: 4em 0;

            .title {
                color: #333;
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 1.5em;
            }


            .list-box {
                user-select: none;
                display: flex;
                flex-wrap: wrap;
                row-gap: 2em;
                margin: 0 -1em;
                justify-content: center;

                .list-item {
                    padding: 0 1em;
                    min-width: 260px;

                    .tab {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background-color: #F6F6F6;
                        border-radius: 4px;
                        height: 105px;
                        padding: 0 1em;
                        background-size: 50%;
                        background-position-x: right;
                        background-position-y: center;
                        background-repeat: no-repeat;

                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;

                        &:not(.active):hover {
                            cursor: pointer;
                            border: 1px solid $ym-main-color;
                        }

                        &.active {
                            background-color: #FFFFFF;
                            box-shadow: 0px 2px 4px 2px #FFE5D6;
                            border: 1px solid $ym-main-color;
                        }
                    }

                    $i: 1;

                    @each $name in (OMS订单管理, TMS运输管理, WMS仓储管理, 数智车务, CRM客户管理, FMS财务管理, HR人力管理, 企业OA) {
                        &:nth-child(#{$i}) {
                            .tab {
                                background-image: url("~@/assets/images/product/footbg/" +$name+".png");
                            }

                            $i: $i+1;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.product-common-page {
    .article {
        .section {
            .layout-main-container {
                padding: 0 3em;
                justify-content: center;
            }
        }
    }
}
</style>