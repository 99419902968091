// 滚动窗口至指定元素可见
function getTop(target) {
    let top = target.offsetTop
    while (target.offsetParent) {
        target = target.offsetParent
        if (target === document.body) {
            return top
        } else {
            top = top + target.offsetTop
        }
    }
    return top
}

export function elementScrollShow(element, offsetY = 0) {
    const top = getTop(element) + offsetY
    window.scrollTo({
        top,
        behavior: "smooth"
    })
}