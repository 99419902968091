<template>
    <div class="big-product-menu" :class="{ show: value }">
        <div class="bg" @mouseenter="me()" @mouseleave="mo()">
            <div class="container">
                <ul class="left-menu">
                    <li class="menu-item-box" :class="{ active: current === item.sublist, 'has-link': item.to }"
                        v-for="item in menulist" @mouseenter="menuEnter(item)" @click="submenuClick($event, item.to)">
                        <div class="menu-title">{{ item.name }}</div>
                    </li>
                </ul>
                <div class="menu-content flex-grow-1">
                    <ul class="sub-menu-list">
                        <li class="sub-menu-list-item" v-for="sub in current" :key="sub.ptitle">
                            <IconTitleText :class="{ 'has-link': sub.to }" :inline="false" :icon="'icon-' + sub.icon"
                                :ptitle="sub.ptitle" :ptext="sub.ptext" @click.native="submenuClick($event, sub.to)">
                            </IconTitleText>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconTitleText from '@/components/IconTitleText.vue';
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const menulist = [
            {
                name: "运脉云OS",
                to: { name: "CloudOS" },
                sublist: [
                    {
                        icon: "oms",
                        ptitle: "OMS订单管理",
                        ptext: ["订单管理", "运价管理", "项目管理"],
                        to: { name: 'OMS' }
                    },
                    {
                        icon: "tms",
                        ptitle: "TMS运输管理",
                        ptext: ["运输管理", "签收管理", "运力管理", "车务管理", "网点线路"],
                        to: { name: 'TMS' }
                    },
                    {
                        icon: "wms",
                        ptitle: "WMS仓库管理",
                        ptext: ["入库管理", "库内管理", "出库管理", "统计分析"],
                        to: { name: 'WMS' }
                    },
                    {
                        icon: "mic",
                        ptitle: "数智车务",
                        ptext: ["车辆档案", "保险管理", "维修保养", "年检管理", "事故违章管理", "油/电管理"],
                        to: { name: 'MIC' }
                    },
                    {
                        icon: "crm",
                        ptitle: "CRM客户管理",
                        ptext: ["客户管理", "承运商管理", "服务商管理"],
                        to: { name: 'CRM' }
                    },
                    {
                        icon: "fms",
                        ptitle: "FMS财务管理",
                        ptext: ["流水台帐", "统计分析"],
                        to: { name: 'FMS' }
                    },
                    {
                        icon: "hr",
                        ptitle: "HR人力管理",
                        ptext: ["司机档案", "准驾管理", "司机招聘", "学习培训"],
                        to: { name: 'HR' }
                    },
                    {
                        icon: "oa",
                        ptitle: "企业OA",
                        ptext: ["审批", "组织架构", "公告", "运信"],
                        to: { name: 'OA' }
                    }
                ]
            },
            {
                name: "云司机",
                to: { name: "CloudDriver" },
                sublist: [
                    {
                        icon: "aiwork",
                        ptitle: "智慧工作台",
                        ptext: ["接单执行", "节点上报", "上岗考勤", "预约排队", "任务导航", "行业应用"],
                        to: { name: "CloudDriver", hash: "aiwork" },
                    },
                    {
                        icon: "safeedu",
                        ptitle: "安全管理",
                        ptext: ["安全教育", "安全码"],
                        to: { name: "CloudDriver", hash: "safeedu" },
                    },
                    {
                        icon: "workchat",
                        ptitle: "工作社交",
                        ptext: ["发现社群", "司机应聘", "运力应召"],
                        to: { name: "CloudDriver", hash: "workchat" },
                    },
                    {
                        icon: "openapi",
                        ptitle: "API开放",
                        ptext: ["API接口开放"],
                        to: { name: "CloudDriver", hash: "openapi" },
                    }
                ]
            },
            {
                name: "IoT物联",
                to: { name: "IoTConnect" },
                sublist: [
                    {
                        icon: "cariot",
                        ptitle: "车辆IoT",
                        ptext: ["链接各类IoT车载智能设备"],
                        to: { name: "IoTConnect", hash: "cariot" },
                    },
                    {
                        icon: "parkiot",
                        ptitle: "园区IoT",
                        ptext: ["打造IoT智慧园区"],
                        to: { name: "IoTConnect", hash: "parkiot" },
                    },
                    {
                        icon: "plantiot",
                        ptitle: "厂区IoT",
                        ptext: ["互联生产设备与仓库IoT"],
                        to: { name: "IoTConnect", hash: "plantiot" },
                    },
                ]
            },
            {
                name: "行业平台",
                sublist: [
                    {
                        icon: "logistics",
                        ptitle: "物流承运商平台",
                        ptext: ["物流多场景", "业务管理平台"],
                        to: { name: "Carrier" }
                    },
                    {
                        icon: "mandt",
                        ptitle: "制造与商贸企业平台",
                        ptext: ["货主型企业", "社群物流管理"],
                        to: { name: "ManufacturingAndTrade" }

                    },
                    {
                        icon: "concrete",
                        ptitle: "商砼运输平台",
                        ptext: ["商砼生产运输一体化管理"],
                        to: { name: "Concrete" }
                    },
                    {
                        icon: "dchemical",
                        ptitle: "危化运输平台",
                        ptext: ["危化运输管理与安全管理"],
                        to: { name: "HC" }
                    },
                    {
                        icon: "coldchain",
                        ptitle: "冷链运输平台",
                        ptext: ["生鲜冷链全程", "可追溯运输管理"],
                        to: { name: "Coldchain" }
                    },
                    {
                        icon: "delivery",
                        ptitle: "家具配送平台",
                        ptext: ["家具运输与装配一体化管理"],
                        to: { name: "Delivery" }
                    },
                    {
                        icon: "other",
                        ptitle: "其他平台",
                        ptext: ["行业协会平台", "客运平台"]
                    },
                ]
            },
        ]
        return {
            menulist,
            current: menulist[0].sublist,
        };
    },
    components: { IconTitleText },
    methods: {
        menuEnter(item) {
            this.current = item.sublist
        },
        submenuClick(e, to) {
            if (!to) {
                return
            }
            to.hash = to.hash || ""
            if (this.$route.name !== to.name) {
                this.$router.push(to)
            } else if (this.$route.hash !== to.hash) {
                this.$router.push(to)
            }
            this.setShow(false)
            this.$emit("input", false)
        },
        me() {
            this.setShow(true)
            this.$emit("input", true)
        },
        mo() {
            this.setShow(false)
            this.$emit("input", false)
        },
        setShow(value) {
            clearTimeout(document.body.$timer)
            document.body.$timer = setTimeout(() => {
                const body = document.body
                if (value) {
                    body.style.overflow = "hidden"
                } else {
                    body.style.overflow = ""
                }
            }, 500);
        }
    },
    updated() {
        this.setShow(this.value)
    },
    created() {
        this.setShow(this.value)
    }
}
</script>

<style lang="scss">
@import "@/sass/util.scss";

.big-product-menu {
    position: fixed;
    left: 0;
    top: 60px;
    height: 0;
    width: 100%;
    background: rgba(#000000, 0.5);
    overflow: hidden;
    transition: all 0.3s ease-in 0.15s;
    z-index: 9999;

    .has-link {
        cursor: pointer;
    }

    &.show {
        height: 100vh;
    }

    .bg {
        height: 600px;
        background: #FFFFFF;
    }


    .container {
        padding-top: 30px;
        height: 100%;
        flex-wrap: nowrap !important;
        display: flex;
    }

    .left-menu {
        background-color: #f8f8f8;
        height: 100%;
        flex-basis: 200px;
        flex-shrink: 0;

        .menu-item-box {
            height: 48px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            &::before {
                content: "";
                width: 4px;
                height: 100%;
                background: linear-gradient(180deg, #FFB28C 0%, #F66E2C 100%);
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }

            &::after {
                content: ">";
                position: absolute;
                right: 16px;
                color: $ym-main-color;
                font-size: 12px;
                opacity: 0;
            }

            &.active {
                background: #FFFFFF;
                box-shadow: -2px 2px 2px 0px rgba(217, 217, 217, 0.5000);

                .menu-title {
                    color: $ym-main-color;
                }

                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .menu-content {
        height: 100%;
        overflow: hidden;

        .sub-menu-list {
            display: flex;
            flex-wrap: wrap;
            row-gap: 32px;

            .sub-menu-list-item {
                flex-basis: 25%;
                flex-grow: 0;
                display: flex;
                justify-content: center;

                .icon-title-text {
                    width: 72%;
                    @include icon-title-text(28px, 20px, 16px, 14px, 16px);
                    user-select: none;

                    .text:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    $iconMap: (
        oms:OMS订单管理,
        tms:TMS运输管理,
        wms:WMS仓库管理,
        mic:数智车务,
        crm:CRM客户管理,
        fms:FMS财务管理,
        hr:HR人力管理,
        oa:企业OA,
        aiwork:智慧工作台,
        safeedu:安全管理,
        workchat:工作社交,
        openapi:API开放,
        cariot:车辆IoT,
        parkiot:园区IoT,
        plantiot:厂区IoT,
        logistics:物流承运商平台,
        mandt:制造与商贸企业平台,
        concrete:商砼运输平台,
        dchemical:危化运输平台,
        coldchain: 冷链运输平台,
        delivery:家具配送平台,
        other:其他平台
    );


@each $icon,
$path in $iconMap {
    .icon-#{$icon} {
        background-image: url("./img/" +$path+"@2x.png");
    }
}
}
</style>