<template>
    <div class="page-clouddriver">
        <Banner v-bind="banner">
            <template slot="btn">
                <button class="ym-btn-2" @click="openDownload()">立即下载</button>
            </template>
        </Banner>
        <div style="padding-top:8.4375rem">
            <h1 class="title-h1">主要功能</h1>
        </div>
        <article class="article">
            <section class="desc-card" id="aiwork">
                <div class="container">
                    <div class="layout-main-aside ">
                        <div class="layout-main-container">
                            <div class="text-area">
                                <title-text ptitle="智慧工作平台" ptext="集成各类工作应用，操作简便，满足不同任务需求"></title-text>
                                <ul class="list-box">
                                    <li class="list-item" v-for="(item, i) in worklist">
                                        <div class="icon-text-group">
                                            <img class="icon" :src="item.iconUrl" :alt="item.title">
                                            <p>{{ item.title }}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="layout-aside-container">
                            <Swiper ref="mySwiper" :options="swiperOptions">
                                <SwiperSlide v-for="(imgurl, index) in imglist" :key="index">
                                    <img :src="imgurl" alt="">
                                </SwiperSlide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section class="desc-card" id="safeedu">
                <div class="container">
                    <div class="layout-main-aside flex-row-reverse">
                        <div class="layout-main-container">
                            <div class="text-area">
                                <title-text ptitle="安全管理" ptext="对司机进行安全教育与管理"></title-text>
                                <title-text class="sub-title-text" ptitle="安全教育" ptext="对司机进行安全教育与管理"></title-text>
                                <title-text class="sub-title-text" ptitle="安全码" ptext="对司机进行安全教育与管理"></title-text>
                            </div>
                        </div>
                        <div class="layout-aside-container">
                            <img src="@/views/CloudDriver/安全管理.png" alt="安全管理">
                        </div>
                    </div>
                </div>
            </section>
            <section class="desc-card" id="workchat">
                <div class="container">
                    <div class="layout-main-aside">
                        <div class="layout-main-container">
                            <div class="text-area">
                                <title-text ptitle="工作社交" ptext="以社交化的方式，发现更多工作机会" />
                                <title-text class="sub-title-text" ptitle="发现社群" ptext="认证司机可关注并加入更多平台或挂靠企业，获得订单与工作" />
                                <title-text class="sub-title-text" ptitle="司机应聘"
                                    ptext="认证司机可获得基于大数据的工作星级评价，以优质司机身份获得更好的工作机会" />
                                <title-text class="sub-title-text" ptitle="运力应招" ptext="关注更多物流企业，以外协运力的方式加入更多挂靠企业" />
                            </div>
                        </div>
                        <div class="layout-aside-container">
                            <img src="@/views/CloudDriver/工作社交.png" alt="工作社交">
                        </div>
                    </div>
                </div>
            </section>
            <section class="desc-card" id="openapi">
                <div class="container layout-main-aside flex-row-reverse">
                    <div class="layout-main-container">
                        <title-text ptitle="API开放" :ptext="['云司机标准API可对接不同平台或系统', '帮助用户简便升级原有系统', '实现企业与司机之间的协同工作']" />
                    </div>
                    <div class="layout-aside-container">
                        <img src="@/views/CloudDriver/API开放.png" alt="API开放">
                    </div>
                </div>
            </section>
        </article>
    </div>
</template>

<script>
import TitleText from '@/components/TitleText.vue';
import Banner from '@/components/Banner.vue';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";


export default {
    components: {
        Banner,
        TitleText,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            banner: {
                title: "云司机",
                text: "社交化的司机互联工作平台\n支持与其他第三方业务系统及物流平台对接",
                isreserver: true,
                background: "linear-gradient(180deg, #EBF0FD 0%, #FFFFFF 100%)",
                imgUrl: require("@/assets/images/banners/云司机.png")
            },
            text: "社交化的司机互联工作平台\n支持与其他第三方业务系统及物流平台对接",
            worklist: [
                "接单执行",
                "在线抢单",
                "节点上报",
                "上岗考勤",
                "排队预约",
                "车辆报修",
                "违规处理",
                "安全教育",
                "行业应用"
            ].map(v => {
                return {
                    title: v,
                    iconUrl: require(`./icon/${v}.png`)
                }
            }),
            imglist: [require("./智慧工作台1.png"), require("./智慧工作台2.png"), require("./智慧工作台3.png")],
            swiperOptions: {
                width: 480,
                autoplay: {
                    disableOnInteraction: false
                },
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
    methods:{
        openDownload(){
            document.getElementById("downloadapp").click()
        }
    }
}
</script>

<style lang="scss">
@import "@/sass/util";

.page-clouddriver {

    // 导航栏特殊处理
    .banner {
        .illustration {
            img {
                width: 50vw;
                max-width: 1000px;
            }
        }

        .slot-img img {
            right: 0;
            height: auto;
        }

        .text {
            margin-bottom: 5rem;
        }
    }

    // 特殊标题
    .title-h1 {
        font-size: 3rem;
        font-weight: bold;
        color: #333333;
        text-align: center;
    }

    .desc-card-header {
        margin-bottom: 0;
    }

    .article {
        .desc-card {
            &:nth-child(2n) {
                background: #F5F7FB;
            }

            &:nth-child(1) {
                .list-box {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 3em;
                    min-width: 360px;
                    max-width: 420px;

                    .list-item {
                        width: 33.3%;
                        margin-bottom: 3em;

                        .icon-text-group {
                            width: 5em;
                            text-align: center;

                            .icon {
                                width: 100%;
                                margin-bottom: 0.2em;
                            }

                            p {
                                font-weight: 400;
                                color: #424242;
                            }
                        }
                    }
                }

            }
        }


        & .layout-main-aside {
            display: flex;
            align-items: center;

            .title-text {
                &:not(.sub-title-text) {
                    @include title-text(2.25rem, 1rem, 0.8em);

                    &+.sub-title-text {
                        margin-top: 3.375rem;
                    }
                }

                &.sub-title-text {
                    @include title-text(1.5rem, 1rem, 0.6em);
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }


        }

        & .layout-aside-container {
            display: flex;
            align-items: center;

            img {
                max-width: 100%;
            }
        }

        & .layout-main-container {
            justify-content: center;
        }
    }

    .swiper-container {
        width: 320px;
        padding-bottom: 40px;

        img {
            width: 320px;
        }

        .swiper-pagination {
            height: 24px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .swiper-pagination-bullet {
                width: 13px;
                height: 13px;
                background: #cacaca;
            }
        }
    }
}
</style>