<template>
    <DescTemp class="news-acts-section" title="新闻动态">
        <div class="d-flex flex-lg-nowrap flex-wrap">
            <div class="news-box">
                <h3 class="d-flex justify-content-between ym-focus-fontt-24">
                    <span class="icon"><span class="icon1"></span> 新闻</span>
                    <router-link class="more-text" :to="{ name: 'OfficialNews' }">更多</router-link>
                </h3>
                <ul class="list-box">
                    <li class="list-box-item" v-for="item in newsList" :key="item.guid" @click="seeNews(item.guid)">
                        <div style="margin-right:8px;">
                            <img width="200" height="120" :src="item.photo" alt="新闻主图">
                        </div>
                        <div class="text-area">
                            <div class="title" v-html="item.title"></div>
                            <div class="date">{{ item.date }}</div>
                            <div class="text" v-html="item.sabstract"></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="d-lg-block d-sm-none split-v"></div>
            <div class="acts-box">
                <h3 class="d-flex justify-content-between ym-focus-fontt-24">
                    <span><span class="icon2"></span>活动</span>
                    <router-link class="more-text" :to="{ name: 'OfficialActivities' }">更多</router-link>
                </h3>
                <ul class="list-box">
                    <li class="list-box-item" v-for="item in activityList" :key="item.guid"
                        @click="seeActivities(item.guid)">
                        <div class="text-area">
                            <div class="title" v-html="item.title"></div>
                            <div class="date">{{ item.date }}</div>
                            <div class="text" v-html="item.sabstract"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </DescTemp>
</template>

<script>
import DescTemp from "@/components/DescTemp.vue";
import IconTitleText from "@/components/IconTitleText.vue";
import { getActivity, getNews } from "@/server/news";
export default {
    components: {
        DescTemp,
        IconTitleText
    },
    data() {
        return {
            newsList: [],
            newstotal: 0,
            activitytotal: 0,
            activityList: []
        }
    },
    created() {
        getNews(1, 2).then(({ data, total }) => {
            this.newsList = data
            this.newstotal = total
        })

        getActivity(1, 2).then(({ data, total }) => {
            this.activityList = data
            this.activitytotal = total
        })
    },
    methods: {
        seeNews(id) {
            this.$router.push({ name: "OfficialNews", query: { id } })
        },
        seeActivities(id) {
            this.$router.push({ name: "OfficialActivities", query: { id } })
        }
    }
}
</script>

<style lang="scss">
@import "@/sass/util";


.news-acts-section {

    .list-box {
        .list-box-item {
            display: flex;
            padding: 1.5em 0;
            cursor: pointer;
            user-select: none;
            overflow: hidden;
            height: calc(120px + 3em);

            &:not(:last-child) {
                border-bottom: 1px solid #ECEEF2;
            }

            .text-area {
                .title {
                    width: 100%;

                    p {
                        width: 100%;
                        font-size: 1.5rem;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #333333;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .date {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #999999;
                    margin: 1em 0;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: "";
                        width: 1.125rem;
                        height: 1.125rem;
                        margin-right: 6px;
                        background: url("@/assets/images/home/时间.png") center/100%;
                    }
                }

                .text {
                    p {
                        font-size: 0.875rem;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow-wrap: break-word;
                        word-break: break-all;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }

    .news-box {
        max-width: 60%;
        flex-shrink: 0;

        .list-box {
            .list-box-item {
                .text-area {
                    width: calc(100% - 208px);
                }
            }
        }

        @include media-breakpoint-down(lg) {
            max-width: 100%;

        }
    }

    .acts-box {
        flex-grow: 1;
        max-width: calc(40% - 5rem);

        .list-box {
            .list-box-item {
                .text-area {
                    width: calc(100%);
                }
            }
        }

        @include media-breakpoint-down(lg) {
            max-width: 100%;
            margin-top: 4em;
        }
    }



    // 分割线
    .split-v {
        background-color: #ECEEF2;
        margin-left: 4rem;
        margin-right: 2rem;
        box-shadow: 0 0 0 0.5px #D8D8D8;
    }

    .more-text {
        font-size: 1rem;
        color: #666;
        font-weight: 400;
        display: flex;
        align-items: center;

        &::after {
            content: "";
            display: inline-block;
            width: 0.8em;
            height: 0.8em;
            background: url(~@/assets/images/icon/新闻更多.png) no-repeat center;
            background-size: contain;
            background-position-y: -1px;
        }
    }


    .icon {
        display: flex;
        align-items: center;
    }

    .icon1 {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(~@/assets/images/home/新闻.png);
        background-size: 100%;
        margin-right: 8px;
    }

    .icon2 {
        @extend .icon1;
        background-image: url(~@/assets/images/home/活动.png);
    }
}
</style>