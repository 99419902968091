export function callus() {
    /**
     * @type {HTMLIFrameElement}
     */
    let lybDom = document.getElementById("nb_nodeboard_close")
    if (lybDom) {
        lybDom.click()
    }
}

export function startUse() {
    window.open("https://mp.yunmai56.com/sbt/#/login", "_blank")
}