<template>
    <div class="layout-main-aside">
        <div class="layout-main-container">
            <slot name="main"></slot>
        </div>
        <div class="layout-aside-container">
            <slot name="aside"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:"main-aside"
}
</script>
