<template>
  <div>
    <Banner v-bind="banner"></Banner>
    <article>
      <div class="container" :id="kind.id" v-for="kind in arr" :key="kind.id">
        <h1 class="title">{{ kind.title }}</h1>
        <ul class="wrapflex-box">
          <li class="flex-item col-lg-2 col-md-3 col-sm-4 col-xs-6 col-6" v-for="eq in kind.eqs" :key="eq.name">
            <img width="120px" height="120px" :src="eq.imgUrl" :alt="eq.name">
            <div class="eq-name">{{ eq.name }}</div>
          </li>
        </ul>
        <div class="footer">
          <button @click="callus()">联系合作</button>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
export default {
  data() {
    return {
      banner: {
        title: "IoT智能设备互联",
        text: "协同IoT物联网设备方，打造数字化智慧物流全场景",
        // background: "linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%)",
        background: "linear-gradient(90deg, #E4E9FB 0%, #F3F6FD 20%, #D1D8F7 100%)",
        fontColorTheme: "dark",
        illustration: { backgroundImage: `url(${require("@/assets/images/banners/IoT智能设备互联.png")})` },
      },
      arr: [
        {
          title: "车辆IoT",
          eqs: ["北斗部标机", "ADAS", "BSD", "DSM", "便携定位终端", "车载摄像头", "智慧交互屏", "车载监控屏", "油量传感器", "温度传感器", "烟感传感器", "三合一喇叭", "电磁门锁", "正反转检测仪", "车载LED显示屏", "载重传感器"].map(v => {
            return {
              name: v,
              imgUrl: require(`@/views/product/iotconnect/eqimg/${v}.png`),
            };
          }),
          id: "cariot"
        },
        {
          title: "园区IoT",
          eqs: ["地磅", "防爆气体检测摄像机", "安防摄像头", "环境监测器", "水质传感器", "停车检测仪"].map(v => {
            return {
              name: v,
              imgUrl: require(`@/views/product/iotconnect/eqimg/${v}.png`),
            };
          }),
          id: "parkiot"
        },
        {
          title: "厂区IoT",
          eqs: ["智能输送带", "罐体传感器", "人脸识别机", "智能托盘", "智能叉车", "RFID标签", "RFID传感器"].map(v => {
            return {
              name: v,
              imgUrl: require(`@/views/product/iotconnect/eqimg/${v}.png`),
            };
          }),
          id: "plantiot"
        }
      ]
    };
  },
  components: { Banner }
}
</script>

<style scoped lang="scss">
.banner {
  height: 600px;
}

.wrapflex-box {
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;

  .flex-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .eq-name {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}

.container {
  padding-top: 80px;

  &:last-child {
    padding-bottom: 80px;
  }

  .title {
    margin-bottom: 60px;
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }

  .footer {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: white;
      font-size: 18px;
      font-weight: 500;
      width: 200px;
      height: 52px;
      background: #F66E2C;
      border-radius: 33px;
    }
  }
}
</style>