<template>
    <div class="section3 d-none d-lg-block">
        <canvas width="1920px" height="1080px" ref="canvas" @mouseout="canvasMouseout()"></canvas>
    </div>
</template>

<script>

const _IconProto_ = {
    isPointInIcon(x, y) {
        if (x === null || y === null || !this.rectArea) {
            return false
        }
        const rate = 1920 / document.documentElement.clientWidth
        const rx = x * rate
        const ry = y * rate
        if (rx >= this.rectArea.x && rx <= (this.rectArea.x + this.rectArea.w) && ry >= this.rectArea.y && ry <= (this.rectArea.y + this.rectArea.h)) {
            return true
        } else {
            return false
        }
    }
}

export default {

    data() {
        return {
            off: [],
            bgImgPromise: new Promise((rs, rj) => {
                const img = document.createElement("img")
                img.src = require("@/assets/images/home/section3/bg.png")
                img.onload = () => {
                    rs(img)
                }
            }),
            isPlay: false,
            iconlist: [
                {
                    globalAlpha: 0.5,
                    name: "数字政务",
                    x: 437,
                    y: 210,
                    w: 54,
                    h: 54,
                },
                {
                    globalAlpha: 1,
                    name: "城市配送",
                    x: 422,
                    y: 462,
                    w: 83.4,
                    h: 83.4,
                },
                {
                    globalAlpha: 1,
                    name: "煤炭运输",
                    x: 596,
                    y: 137,
                    w: 52,
                    h: 52,
                },
                {
                    globalAlpha: 1,
                    name: "港口物流",
                    x: 600,
                    y: 321,
                    w: 43.4,
                    h: 43.4,
                },
                {
                    globalAlpha: 1,
                    name: "行业协会",
                    x: 600,
                    y: 469,
                    w: 43.4,
                    h: 43.4,
                },
                {
                    globalAlpha: 1,
                    name: "快消品运输",
                    x: 708,
                    y: 82,
                    w: 42.8,
                    h: 42.8,
                },
                {
                    globalAlpha: 1,
                    name: "生物冷链",
                    x: 698,
                    y: 227,
                    w: 66,
                    h: 66,
                },
                // todo
                {
                    globalAlpha: 1,
                    name: "家具运输",
                    x: 809,
                    y: 123,
                    w: 66,
                    h: 66,
                    to: { name: "Delivery" }
                },
                {
                    globalAlpha: 1,
                    name: "危化运输",
                    x: 803,
                    y: 354,
                    w: 79.2,
                    h: 79.2,
                    to: { name: "HC" }
                },
                {
                    globalAlpha: 1,
                    name: "快速运输",
                    x: 928,
                    y: 223,
                    w: 68,
                    h: 68,
                },
                {
                    globalAlpha: 1,
                    name: "挖机运输",
                    x: 940,
                    y: 389,
                    w: 42,
                    h: 42,
                },
                {
                    globalAlpha: 1,
                    name: "渣土运输",
                    x: 1054,
                    y: 125,
                    w: 93,
                    h: 93,
                },
                {
                    globalAlpha: 1,
                    name: "制造&商贸企业",
                    x: 1068,
                    y: 311,
                    w: 43.4,
                    h: 43.4,
                    to: { name: "ManufacturingAndTrade" }

                },
                {
                    globalAlpha: 1,
                    name: "商砼运输",
                    x: 1074,
                    y: 414,
                    w: 52,
                    h: 52,
                    to: { name: "Concrete" }

                },
                {
                    globalAlpha: 1,
                    name: "零担专线",
                    x: 1211,
                    y: 131,
                    w: 56,
                    h: 56,
                },
                {
                    globalAlpha: 1,
                    name: "生鲜冷链",
                    x: 1229,
                    y: 339,
                    w: 43.4,
                    h: 43.4,
                    to: { name: "Coldchain" }
                },
                {
                    globalAlpha: 1,
                    name: "城市环卫",
                    x: 1214,
                    y: 480,
                    w: 66,
                    h: 66,
                },
                {
                    globalAlpha: 1,
                    name: "汽贸运输",
                    x: 1331,
                    y: 281,
                    w: 43.4,
                    h: 43.4,
                },
                {
                    globalAlpha: 1,
                    name: "数字仓储",
                    x: 1331,
                    y: 410,
                    w: 51,
                    h: 51,
                },
                {
                    globalAlpha: 1,
                    name: "大件运输",
                    x: 1427,
                    y: 80,
                    w: 63,
                    h: 63,
                },
                {
                    globalAlpha: 1,
                    name: "钢铁运输",
                    x: 1449,
                    y: 339,
                    w: 43.4,
                    h: 43.4,
                },
            ].map(v => {
                v.imgPromise = new Promise((rs, rj) => {
                    const img = document.createElement("img")
                    img.src = require(`@/assets/images/home/section3/${v.name}.png`)
                    img.onload = () => {
                        rs(img)
                    }
                    if (v.to) {
                        const hoverImg = document.createElement("img")
                        hoverImg.src = require(`@/assets/images/home/section3/${v.name}hover.png`)
                        hoverImg.onload = () => {
                            v.hoverImg = hoverImg
                        }
                    }
                })

                v.imgUrl = require(`@/assets/images/home/section3/${v.name}.png`)
                Object.setPrototypeOf(v, _IconProto_)
                return v
            }),
            hoverPointx: null,
            hoverPointy: null,
            keyFrame: 0,
        }
    },
    mounted() {

        Promise.all([this.bgImgPromise].concat(this.iconlist.map(v => v.imgPromise))).then(resp => {
            // 所有图片资源都加载完毕后执行
            this.bgImg = resp.shift()
            for (let index = 0; index < resp.length; index++) {
                this.iconlist[index].img = resp[index]
            }
            /**
             * @type { CanvasRenderingContext2D }
             */
            const ctx = this.canvas.getContext("2d")
            this.reFresh(ctx)
            const scrollcb = () => {
                const viewHeight = window.innerHeight || document.documentElement.clientHeight;
                const { top, bottom } = this.canvas.getBoundingClientRect();
                if (top < viewHeight && bottom > 0) {
                    console.log("play")
                    this.play(ctx)
                } else {
                    console.log("stop")
                    this.stop(ctx)
                }
            }
            scrollcb()
            document.addEventListener("scroll", scrollcb)
            this.off.push(() => {
                document.removeEventListener("scroll", scrollcb)
            })

            this.canvasClick()
            this.canvasMouseMove()
        })
    },
    methods: {
        /**
         * 
         * @param {CanvasRenderingContext2D} ctx 
         */
        drawBg(ctx) {
            ctx.drawImage(this.bgImg, 0, 0, 1920, 1080)
        },
        /**
        * @param {CanvasRenderingContext2D} ctx 
        */
        drawIcon(ctx, icon) {
            ctx.globalAlpha = this.keyFrame
            if (icon.to) {
                if (icon.isPointInIcon(this.hoverPointx, this.hoverPointy)) {
                    Promise.resolve(1).then(() => {
                        this.canvas.style.cursor = "pointer";
                    })

                    if (ctx.globalAlpha === 1) {
                        const ls = icon.w * 0.1
                        ctx.drawImage(icon.hoverImg, icon.x - ls, icon.y - ls, icon.w * 1.2, icon.h * 1.2)
                    } else {
                        ctx.drawImage(icon.hoverImg, icon.x, icon.y, icon.w, icon.h)
                    }
                } else {
                    this.canvas.style.cursor = "initial";
                    ctx.drawImage(icon.hoverImg, icon.x, icon.y, icon.w, icon.h)
                }

            } else {
                ctx.drawImage(icon.img, icon.x, icon.y, icon.w, icon.h)
            }

            const fw = ctx.measureText(icon.name).width
            const rate = 1920 / document.documentElement.clientWidth
            ctx.fillText(icon.name, (icon.w - fw) / 2 + icon.x, icon.y + icon.h + 21 * rate)
            icon.rectArea = { w: Math.max(fw, icon.w), h: icon.h + 21 * rate, x: Math.min((icon.w - fw) / 2 + icon.x, icon.x), y: icon.y }
        },

        reFresh(ctx) {
            this.canvas.height = 1080 * this.devicePixelRatio
            this.canvas.width = 1920 * this.devicePixelRatio
            ctx.scale(this.devicePixelRatio, this.devicePixelRatio)


            const rate = 1920 / document.documentElement.clientWidth
            ctx.font = `${14 * rate}px SourceHanSansCN-Regular`
            ctx.fillStyle = "#fff"
            ctx.clearRect(0, 0, 1920, 1080)
            this.drawBg(ctx)
            for (let index = 0; index < this.iconlist.length; index++) {
                this.drawIcon(ctx, this.iconlist[index])
            }
        },

        play(ctx, self = false) {
            if (!this.isPlay || self) {
                if (!this.isPlay) {
                    this.isPlay = true
                    let timer = setInterval(() => {
                        this.keyFrame += 1 / 300
                        if (this.keyFrame >= 1) {
                            this.keyFrame = 1
                            clearInterval(timer)
                            console.log("停止了")
                        }
                    }, 10)
                }
                if (requestAnimationFrame) {
                    this.canvas.timer = requestAnimationFrame((timestap) => {
                        if (!this.start || timestap - this.start >= 100) {
                            this.reFresh(ctx)
                            this.start = timestap
                        }
                        this.play(ctx, true)
                    })
                } else {
                    this.canvas.timer = setTimeout(() => {
                        this.reFresh(ctx)
                        this.play(ctx, true)
                    }, 100)
                }
            }
        },
        stop() {
            if (this.isPlay) {
                this.keyFrame = 0
                this.isPlay = false
                if (cancelAnimationFrame) {
                    cancelAnimationFrame(this.canvas.timer)
                    this.start = undefined
                } else {
                    clearTimeout(this.canvas.timer)
                }
            }
        },
        canvasClick() {
            const cb = (e) => {
                this.iconlist.forEach((icon, index) => {
                    if (icon.isPointInIcon(e.offsetX, e.offsetY)) {
                        if (icon.to) {
                            this.$router.push(icon.to)
                        }
                    } else {
                    }
                })
            }
            this.canvas.addEventListener("click", cb)
            this.off.push(() => {
                this.canvas.removeEventListener("click", cb)
            })
        },
        canvasMouseMove() {
            const cb = (e) => {
                this.hoverPointx = e.offsetX
                this.hoverPointy = e.offsetY
            }
            this.canvas.addEventListener("mousemove", cb)
            this.off.push(() => {
                this.canvas.removeEventListener("mousemove", cb)
            })
        },
        canvasMouseout() {
            this.hoverPointx = null
            this.hoverPointy = null
        }
    },
    computed: {
        canvas() {
            return this.$refs.canvas
        },
        devicePixelRatio() {
            return window.devicePixelRatio || 1
        }
    },
    beforeDestroy() {
        console.log("组件销毁触发")
        this.stop()
        this.off.forEach(cb => cb())
    }
}
</script>

<style lang="scss">
@import "@/sass/util";

.section3 {
    // height: 1080px;

    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;

    canvas {
        position: absolute;

        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // left: 50%;
        // transform: translateX(-50%);
    }




    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .icon-box {
        position: absolute;
        color: #fff;
        font-size: 13px;
        text-align: center;

        .icon {
            margin-bottom: 10px;
        }
    }


}
</style>