import { dateFormat } from "@/utils/dateUtils"
import axios from "axios"


const re = /\<p\>(.*?)\<br\>\<\/p\>/
/**
 * @export
 * @param {number} [pageNo=1]
 * @param {number} [size=10]
 * @return {Promise<{total:number,empty:boolean,data:{guid:stirng,createTime:number,content:string,editTime:number,isDelete:0|1,photo:string,readingNumber:number,sabstract:string,title:string,type:1}[]}>} 
 */
export function getNews(pageNo = 1, size = 10) {
    return base(0, pageNo, size)
}

/**
 * @export
 * @param {number} [pageNo=1]
 * @param {number} [size=10]
 * @return {Promise<{total:number,empty:boolean,data:{guid:stirng,createTime:number,content:string,editTime:number,isDelete:0|1,photo:string,readingNumber:number,sabstract:string,title:string,type:1}[]}>} 
 */
export function getActivity(pageNo = 1, size = 10) {
    return base(1, pageNo, size)
}

export function base(type, pageNo, size) {
    return new Promise((resolve, reject) => {
        axios.get("/ultraman-web-ui/api/homepage/list", {
            params: { type, pageNo, size }
        }).then(({ statusText, data }) => {
            if (statusText === "OK") {
                if (data?.result?.data) {
                    for (let i = 0; i < data.result.data.length; i++) {
                        data.result.data[i].date = dateFormat(new Date(data.result.data[i].createTime), 'YYYY-MM-dd')
                        if (type === 1) {
                            const content = JSON.parse(data.result.data[i].content)
                            data.result.data[i].content = `
                                <h1>${data.result.data[i].title}</h1>
                                <p class='data_time'>${data.result.data[i].date}</p>
                                <div class="content">
                                    <div class="abstract">${data.result.data[i].sabstract}</div>
                                    <div class="details">
                                        <ul class="active-img-box">${content.list.map(v => `<li class="active-img"><img src="${content.headurl + v.url}" alt="" /></li>`).join("")}</ul>
                                    </div>
                                </div>
                            `
                        } else if (type === 0) {
                            data.result.data[i].content = `
                            <h1>${data.result.data[i].title}</h1>
                            <p class='data_time'>${data.result.data[i].date}</p>
                            <div class="content">
                                <div class="abstract">${data.result.data[i].sabstract}</div>
                                <div class="details">
                                ${data.result.data[i].content}
                                </div>
                            </div>
                            `
                        }
                    }
                } else {
                    if (data?.result) {
                        data.result.data = []
                    } else {
                        data.result = { total: 0, data: [] }
                    }
                }
                resolve(data.result)
            } else {
                reject()
            }
        }, reject)
    })
}

export function addReadingNumber(guid) {
    return axios.post('/ultraman-web-ui/api/homepage/addReadingNumber', { guid })
}