// 8个产品界面的model数据源

export const productlist = [
    {
        id: "page-oms",
        name: "OMS",
        path: "oms",
        banner: {
            title: "OMS订单管理",
            text: "多渠道订单统一处理，跨企业在线流转",
            background: "linear-gradient(354deg, #F8F9FD 0%, #E8EAFF 76%, #DFE3FD 100%)",
            fontColorTheme: "dark",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/OMS订单管理.png")})` },
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "ddgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/订单管理.png"),
                    title: "订单管理",
                    texts: [
                        "实现对在线订单、自录订单与API对接订单的统一管理",
                        "一单到底、全程追溯，订单云端存储，随时查询",
                        "订单数据根据组织归集，加强对业务信息的管理",
                        "移动端与网页端均可处理订单，工作不受限"
                    ],
                },
                {
                    id: "yjgl",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/运价管理.png"),
                    title: "运价管理",
                    texts: [
                        "根据线路、货物类型、运输方式等，自由设置运输价格，\n方便您的客户在线下单时获取预估运价",
                        "支持灵活定价，可根据合同、合作方式、客户类型等设置不同优惠折扣",
                    ], // 统一按列表渲染。
                },
                {
                    id: "xmgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/项目管理.png"),
                    title: "项目管理",
                    texts: [
                        "根据运输合同建立项目档案，运力调度、业务结算依据项目进行自动处理",
                    ], // 统一按列表渲染。
                }
            ]
        }
    },
    {
        id: "page-tms",
        name: "TMS",
        path: "tms",
        banner: {
            title: "TMS运输管理",
            text: "适配多种细分运输业务，运输高效协作",
            background: "linear-gradient(135deg, #928AFF 0%, #39E8FF 100%) center",
            fontColorTheme: "light",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/TMS运输管理.png")})` },
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "ysgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/运输管理.png"),
                    title: "运输管理",
                    texts: [
                        "业务全流程数字化管理，实现运输上下游之间的业务流转和工作协同",
                        "搭配云司机APP可实现与司机间的任务互联",
                    ],

                },
                {
                    id: "qsgl",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/签收管理.png"),
                    title: "签收管理",
                    texts: [
                        "扫码签收、电子签名，保障签收工作合规性，提高签收效率",
                        "减少打印、回收送货单的物流和人力成本",
                        "正常签收、异常签收全部备记在云端，随时查阅，结算纠纷有依据"
                    ],
                },
                {
                    id: "ylgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/运力管理.png"),
                    title: "运力管理",
                    texts: [
                        "全局性可视化混合自有运力、合同运力和社会运力，调度界面直观清晰",
                        "运力热力图，随时了解企业运力缺口"
                    ],
                }
            ]
        }
    },
    {
        id: "page-wms",
        name: "WMS",
        path: "wms",
        banner: {
            title: "WMS仓储管理",
            text: "精细化仓储及仓配一体化管理，提高出入仓工作效率",
            background: "linear-gradient(354deg, #F8F9FD 0%, #E8EAFF 76%, #DFE3FD 100%)",
            fontColorTheme: "dark",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/WMS仓储管理.png")})` },
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "ysgl",
                    img: require("@/assets/images/product/illustration/仓储管理.png"),
                    isreverse: false,
                    texts: [
                        "以仓储过程管理为核心",
                        "出入库单据与订单、运输单关联，强化供应链管理",
                        "提高出入仓工作效率，降低人力成本"
                    ],

                }
            ]
        }
    },
    {
        id: "page-mic",
        name: "MIC",
        path: "mic",
        banner: {
            title: "数智车务",
            text: "全生命周期精细化车辆管控，优化车务成本",
            background: "linear-gradient(354deg, #F8F9FD 0%, #E8EAFF 76%, #DFE3FD 100%)",
            fontColorTheme: "dark",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/数智车务.png")})` },
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "ysgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/数智车务.png"),
                    suffixTitle: "帮助企业从购车、维修、保养、保险等方面全周期管理车辆，提高车辆利用效率，合理统筹车务管理支出，智能提醒车务相关事项",
                    texts: [
                        "全生命周期管理车辆",
                        "管家式智能提醒车务相关事项",
                        "提供车务管理统筹优化建议"
                    ],

                }
            ]
        }
    },
    {
        id: "page-crm",
        name: "CRM",
        path: "crm",
        banner: {
            title: "CRM客户管理",
            text: "客户资源统一管理，帮助精准营销",
            background: "linear-gradient(344deg, #8ABCEE 0%, #ECA1FE 100%)",
            fontColorTheme: "light",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/CRM客户管理.png")})` },
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "ysgl",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/客户管理.png"),
                    texts: [
                        "分级分层管理客户档案，数字化直观展现客户合作质量",
                        "严格把控企业核心信息，客户资源按组织分配，员工离职不可查看，保障客户信息安全不泄露",
                        "精准运营重点客户，提升对客户的服务水平"
                    ],

                }
            ]
        }
    },
    {
        id: "page-fms",
        name: "FMS",
        path: "fms",
        banner: {
            title: "FMS财务管理",
            text: "业务、财务数据互联互通，简化财务工作",
            background: "linear-gradient(351deg, #D3E4FA 0%, #FFFFFF 100%)",
            fontColorTheme: "dark",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/FMS财务管理.png")})` },
        },
        body: {
            hasNav: false,
            list: [
                {
                    id: "ysgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/财务管理.png"),
                    title: "",
                    texts: [
                        "以电子订单为基础，自动归集各类账务数据，实现业务数据和财务数据实时互联流转",
                        "自动核对财务数据，简化人工作业，提高对账准确度",
                        "提供各类财务报表，准确实时地了解企业资金状况，优化成本管理"
                    ],

                }
            ]
        }
    },
    {
        id: "page-hr",
        name: "HR",
        path: "hr",
        banner: {
            title: "HR人力管理",
            text: "业务、财务数据互联互通，简化财务工作",
            background: "linear-gradient(318deg, #8EC5FC 0%, #E0C3FC 100%, #E0C3FC 100%)",
            fontColorTheme: "light",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/HR人力管理.png")})` },
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "sjda",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/司机档案.png"),
                    title: "司机档案",
                    texts: [
                        "建立司机工作档案，从工作评价、安全驾驶习惯、专业技能、心理评价等维度生成司机大数据画像",
                    ],
                },
                {
                    id: "zjgl",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/准驾管理.png"),
                    title: "准驾管理",
                    texts: [
                        "规范人车绑定管理，非车辆指定驾驶司机无法扫码上岗，事故追责有依据",
                    ],

                },
                {
                    id: "sjzp",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/司机招聘.png"),
                    title: "司机招聘",
                    texts: [
                        "在海量专业司机库里，进行基于司机画像的司机招聘，好司机不用愁",
                        "每一分努力都被数据记录，获得真实的工作履历"
                    ],
                },
                {
                    id: "xxpx",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/学习培训.png"),
                    title: "学习培训",
                    texts: [
                        "线上线下结合开展司机安全培训，全面提高司机安全意识",
                        "新司机培训与就业再教育，帮助司机快速提高专业技能，掌握更好的工作能力"
                    ],
                }
            ]
        }
    },
    {
        id: "page-oa",
        name: "OA",
        path: "oa",
        banner: {
            title: "企业OA",
            text: "实现内部工作协同，提升跨部门协作效率",
            background: "linear-gradient(136deg, #005BEA 0%, rgba(0, 198, 251, 0.2000) 100%)",
            fontColorTheme: "light",
            illustration: { backgroundImage: `url(${require("@/assets/images/banners/企业OA.png")})` },
        },
        body: {
            hasNav: true,
            list: [
                {
                    id: "zzyqx",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/组织与权限.png"),
                    title: "组织与权限",
                    texts: [
                        "组织化规范企业管理，保障企业业务安全和数据安全",
                        "支持集团型企业添加子公司，自上而下分级管理",
                        "按组织、岗位、角色设置不同操作与查看权限，灵活方便，\n单个应用也可独立分配权限"
                    ],

                },
                {
                    id: "sp",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/审批.png"),
                    title: "审批",
                    texts: [
                        "审批流程关联各项业务表单，涵盖业务、\n调度、车务、财务等多种管理场景",
                        "零代码配置自定义审批流程，支持数据权限设置"
                    ],

                },
                {
                    id: "gg",
                    isreverse: false,
                    img: require("@/assets/images/product/illustration/公告.png"),
                    title: "公告",
                    texts: [
                        "一键下发公告，可查看已读/未读人员",
                        "社群内可跨组织发布公告，方便公示重要事项"
                    ],

                },
                {
                    id: "yx",
                    isreverse: true,
                    img: require("@/assets/images/product/illustration/运信.png"),
                    title: "运信",
                    texts: [
                        "各类型消息智能分类，一目了然、井井有条",
                        "PC端和APP端消息同步，随时接上沟通",
                        "界面可关联业务单据，沟通更简洁高效",
                        "与微信消息打通，更好服务客户",
                    ],
                }
            ]
        }
    }
]

export const productChildren = productlist.map(v => {
    return {
        path: v.path,
        name: v.name,
        component: {
            render(h) {
                return h('product-creator', {
                    props: {
                        banner: v.banner,
                        body: v.body,
                    },
                    class: v.id,
                })
            }
        },
        meta: {
            parent: "product"
        }
    }
})