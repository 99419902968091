<template>
    <DescTemp title="行业平台">
        <div class="my-swiper">
            <div class="prevbtn" @click="swiper.slidePrev()"></div>
            <Swiper class="swiper-no-swiping" ref="mySwiper" :options="swiperOptions">
                <SwiperSlide v-for="(item, i) in platform" :key="i">
                    <div class="hy-item" @click="go(item.to)">
                        <img :src="item.imgUrl" :alt="item.title">
                        <div class="mask"></div>
                        <div class="info-area">
                            <i class="icon" :class="'icon' + i"></i>
                            <div class="title">{{ item.title }}</div>
                            <div class="split"></div>
                            <pre class="text">{{ item.text }}</pre>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="nextbtn" @click="swiper.slideNext()"></div>
        </div>
    </DescTemp>
</template>

<script>
import DescTemp from '@/components/DescTemp.vue';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
    components: { DescTemp, Swiper, SwiperSlide },
    data() {
        return {
            platform: [
                {
                    title: "物流承运商平台",
                    text: "帮助物流企业整合业务资源与运力资源，与运输上下游进行高效的协同工作，提供丰富的场景化运输解决方案",
                    to: { name: "Carrier" },
                },
                {
                    title: "制造与商贸企业平台",
                    text: "帮助货主企业规范运输管理，协同生产、经销与运输管控，优化物流成本，数字化管理承运商",
                    to: { name: "ManufacturingAndTrade" },
                },
                {
                    title: "商砼运输平台",
                    text: "提供生产运输一体化协作管理，帮助商砼企业实现产业链协同数字化管理，打造智慧砼站",
                    to: { name: "Concrete" },
                },
                {
                    title: "危化运输平台",
                    text: "提供涵盖运输管理与安全管理的数字化平台，提高运输效率与安全管控能力",
                    to: { name: "HC" },
                },
                {
                    title: "冷链运输平台",
                    text: "为冷运企业提供精细化数字仓配一体管理，实现生鲜冷链运输透明化、数字化、互联化管理",
                    to: { name: "Coldchain" },
                },
                {
                    title: "家具配送平台",
                    text: "为家居供应链物流提供全流程一体化技术解决方案，解决销售、运输与送装各环节之间信息互通与流程协作问题",
                    to: { name: "Delivery" },
                },
                {
                    title: "行业协会平台",
                    text: "实现协会对入会企业及驾驶员进行垂直管理，加强协会数字化管理能力",
                },
                {
                    title: "其它平台",
                    text: "非货运行业解决方案\n客运行业平台 城市环卫平台",
                }
            ].map(v => {
                v.imgUrl = require(`./section4/${v.title}.png`)
                return v
            }),
            swiperOptions: {
                slidesPerView: 4,
                spaceBetween: 20,
                autoplay: false,
                loop: true,
                breakpoints: {
                    1366: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 12,
                    },
                    576: {
                        slidesPerView: 1,
                    }
                },
                on: {
                    init() {
                        this.slides.on('mouseenter', (e) => {
                            const n = this.loopedSlides
                            const len = n + this.activeIndex
                            const width = this.width
                            const space = this.params.spaceBetween
                            for (let i = this.activeIndex; i < len; i++) {
                                const dom = this.slides.eq(i)
                                if (dom[0].contains(e.target)) {
                                    dom[0].getElementsByClassName("mask")[0].style.backgroundColor = "rgba(0,0,0,.1)";
                                    dom[0].style.width = (width - space * (n - 1)) / (n + 1) * 2 + 'px'
                                } else {
                                    dom[0].style.width = (width - space * (n - 1)) / (n + 1) + 'px'
                                }
                            }
                        }).on('mouseleave', () => {
                            const n = this.loopedSlides
                            const len = n + this.activeIndex
                            const width = this.width
                            const space = this.params.spaceBetween
                            for (let i = this.activeIndex; i < len; i++) {
                                const dom = this.slides.eq(i)
                                dom[0].style.width = (width - space * (n - 1)) / n + 'px'
                                dom[0].getElementsByClassName("mask")[0].style.backgroundColor = "rgba(0,0,0,.5)";
                            }
                        })

                        setTimeout(() => {
                            console.log("延迟调用更新")
                            this.update()
                        }, 100)
                    }
                }
            },
        }
    },
    methods: {
        go(to) {
            if (to) {
                this.$router.push(to)
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiperInstance
        }
    },
    beforeDestroy() {
        this.swiper.destroy(false)
    }
}
</script>

<style lang="scss" scoped>
::v-deep.my-swiper {
    .swiper-container {
        width: calc(100% - 200px);
    }

    display: flex;
    align-items: center;

    .swiper-slide {
        transition: all 0.3s ease-in-out;
    }

    .hy-item {
        height: 290px;
        transition: all .3s ease-in-out;
        position: relative;
        overflow: hidden;
        color: #fff;
        padding: 24px;

        .mask {
            background-color: rgba(#000, 0.5);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out;
        }

        img {
            height: 100%;
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
        }

        .info-area {
            width: calc(100% - 48px);
            position: absolute;
            top: 64%;
            transition: all .3s ease-in-out;

            .icon {
                display: block;
                width: 40px;
                height: 40px;
                margin-bottom: 16px;
            }

            .title {
                font-size: 16px;
                white-space: nowrap;
            }

            .split {
                transition: all .3s ease-in-out;
                transition-delay: .3s;
                width: 30px;
                height: 1px;
                background: #fff;
                margin: 13px 0;
                opacity: 0;
            }

            .text {
                transition: all .3s ease-in-out;
                transition-delay: .3s;
                opacity: 0;
                white-space: pre-wrap;
            }
        }

        &:hover {
            .info-area {
                top: 36%;

                .split {
                    opacity: 1;
                }

                .text {
                    opacity: 1;
                }
            }
        }

        &.ishide {
            flex-basis: 0;
            margin-right: 0;
            padding: 0;
        }


        $i: 0;

        @each $item in ("物流", "商贸", "商砼", "危化", "冷链", "家具", "协会", "其他") {

            .icon#{$i} {
                background-image: url("~@/views/CloudOS/section4/" +$item+".png");
                background-size: 100%;
            }

            $i: $i+1;

        }
    }
}
</style>