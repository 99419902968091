<template>
    <div class="icon-title-text" :class="{ inline }">
        <div class="icon">
            <span v-if="icon" :class="icon"></span>
            <slot name="icon"></slot>
        </div>
        <title-text v-bind="$props"></title-text>
    </div>
</template>

<script>
import TitleText, { titleTextProps } from './TitleText.vue'

export default {
    props: {
        ...titleTextProps,
        icon: {
            type: String,
            default: ""
        },
        inline: {
            type: Boolean,
            default: true
        }
    },
    components: { TitleText },
}
</script>