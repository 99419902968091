import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeMain from '@/views/HomeMain/index.vue'
import CloudOS from '@/views/CloudOS/index.vue'
import CloudDriver from '@/views/CloudDriver/index.vue'
import IoTConnect from '@/views/product/iotconnect/IoTConnect.vue'
import { productChildren } from './product'
import { platformChildren } from './platform'
import { elementScrollShow } from "@/utils/elementScrollShow"
import { callus, startUse } from '@/utils/funcbtn'


Vue.use(VueRouter)

/** @type {import('vue-router').RouteConfig []} */
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeMain,
  },

  {
    path: '/pageproduct',
    component: {
      render: h => h('router-view')
    },
    beforeEnter(to, from, next) {
      if (to.name) {
        next()
      } else {
        next(from)
      }
    },
    children: [
      {
        path: 'cloudos',
        component: {
          render: h => h('router-view')
        },
        children: [
          {
            path: "",
            name: 'CloudOS',
            component: CloudOS,
            meta: {
              parent: 'product'
            },
          },
          ...productChildren,
        ]
      },
      {
        path: 'clouddriver',
        name: 'CloudDriver',
        meta: {
          parent: 'product'
        },
        component: CloudDriver
      },
      {
        path: 'iotconnect',
        name: 'IoTConnect',
        component: IoTConnect,
        meta: {
          parent: 'product'
        },
      },
      {
        path: 'platforms',
        component: {
          render: h => h('router-view')
        },
        children: [
          {
            path: "",
            name: 'Platforms',
            meta: {
              parent: 'product'
            },
            redirect: { name: "Concrete" }
          },
          ...platformChildren,
        ]
      },
    ]
  },
  {
    path: '/pagenews',
    name: 'OfficialNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/OfficialNews/news.vue')
  },
  {
    path: '/pageactivities',
    name: 'OfficialActivities',
    component: () => import(/* webpackChunkName: "about" */ '../views/OfficialActivities/activities.vue')
  },
  {
    path: '/about',
    name: 'AboutYM',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutYM/AboutYM.vue'),
    meta: {
      footerBanner: {
        title: "让物流更简单高效"
      }
    }
  },
  {
    path: '/pagechannelcoop',
    name: 'ChannelCoop',
    component: () => import(/* webpackChunkName: "DevCoop" */ '../views/ChannelCoop/index.vue'),
    meta: {
      footerBanner: {
        title: "我们期待与更多渠道商伙伴共创共赢",
        btns: [{
          text: "申请合作",
          click: callus,
          theme: "btn2"
        }]
      }
    }
  },
  {
    path: '/devcoop',
    name: 'DevCoop',
    component: () => import(/* webpackChunkName: "DevCoop" */ '../views/DevCoop/DevCoop.vue'),
    meta: {
      footerBanner: {
        title: "与开发者伙伴一起打造全场景数字物流",
        btns: [{
          text: "申请合作",
          click: callus,
          theme: "btn2"
        }]
      }
    }
  },
  {
    path: '/ecologicalcoop',
    name: 'EcologicalCoop',
    component: () => import(/* webpackChunkName: "EcologicalCoop" */ '../views/EcologicalCoop/EcologicalCoop.vue'),
    meta: {
      footerBanner: {
        title: "我们期待与更多优质生态伙伴共享数字经济",
        btns: [{
          text: "申请合作",
          click: callus,
          theme: "btn2"
        }]
      }
    }
  },
  {
    path: '/appcenter',
    name: 'AppCenter',
    component: () => import(/* webpackChunkName: "AppCenter" */ '../views/AppCenter/AppCenter.vue'),
    meta: {
      footerBanner: {
        title: "立刻体验高效互联的智慧物流",
        btns: [{
          text: "开始使用",
          click: startUse,
          theme: "btn2"
        }]
      }
    }
  },
  {
    path: '*',
    redirect: { name: 'home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const dom = document.getElementById(to.hash)
      elementScrollShow(dom, -132)
    } else if (to.query.id) {
      document.documentElement.scrollTop = 0
    } else {
      console.log(to.meta.scrollTop)
      window.scrollTo({
        top: to.meta.scrollTop || 0,
        left: 0,
        behavior: "auto"
      })
    }
  },
})
router.beforeEach((to, from, next) => {
  if (from.name === "OfficialNews" || from.name === "OfficialActivities") {
    if (from.query.id) {
      next()
      return
    }
  }
  from.meta.scrollTop = document.documentElement.scrollTop
  console.log("from name" + from.name, from.meta.scrollTop)
  next()
})

export default router
