import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/sass/index.scss"
import components from './components'
import 'swiper/css/swiper.css';
import { callus, startUse } from './utils/funcbtn'
import { breakpoint } from './utils/breakpoint'


function isMobile() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"];
  var flag = false;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = true;
      break;
    }
  }
  return flag;
}

if (isMobile()) {
  window.open(window.location.origin + "/phone/index.html")
} else {
  Vue.config.productionTip = false
  Vue.use(components)
  Vue.prototype.callus = callus
  Vue.prototype.startUse = startUse

  Vue.prototype.breakpoint = breakpoint


  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');



  // 添加微信追踪服务
  // 文档地址 https://developers.e.qq.com/docs/guide/conversion/new_version/Web_js
  (function () {
    !function (g, d, t, e, v, n, s) {
      if (g.gdt) return;
      v = g.gdt = function () {
        v.tk ? v.tk.apply(v, arguments) : v.queue.push(arguments)
      };
      v.sv = '1.0';
      v.bt = 0;
      v.queue = [];
      n = d.createElement(t);
      n.async = !0;
      n.src = e;
      s = d.getElementsByTagName(t)[0];
      s.parentNode.insertBefore(n, s);
    }(window, document, 'script', '//qzonestyle.gtimg.cn/qzone/biz/gdt/dmp/user-action/gdtevent.min.js');
    gdt('init', '1200835068');
    gdt('track', '');
  })();


  // 360

  // (function (b, a, e, h, f, c, g, s) {
  //   b[h] = b[h] || function () { (b[h].c = b[h].c || []).push(arguments) };
  //   b[h].s = !!c; g = a.getElementsByTagName(e)[0]; s = a.createElement(e);
  //   s.src = "//s.union.360.cn/" + f + ".js"; s.defer = !0; s.async = !0; g.parentNode.insertBefore(s, g)
  // })(window, document, "script", "_qha", 345082, false);
  //

  (function () {
    window._hmt = window._hmt || [];
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?9c8d890dd9430eef4ff01b2fef337831";
    var s = document.getElementsByTagName("script")[0];
    hm.onload = function () {
      init1()
      init2()
    }

    function init1() {
      setTimeout(_ => {
        let ins = document.getElementById("newBridge")
        let lybdom = document.getElementById("nb_nodeboard_close")

        if (lybdom !== null) {
          if (lybdom.className.indexOf("nb-nodeboard-max") === -1) {
            lybdom.click()
          }
          lybdom.onclick = () => {
            if (lybdom.className.indexOf("nb-nodeboard-max") === -1) {
              ins.className = "show"
            } else {
              ins.className = ""
            }
          }
        } else {
          init1()
        }
      })
    }

    function init2() {
      setTimeout(_ => {
        let secDomlist = document.querySelectorAll(".custom-select-list-item")
        if (secDomlist.length === 0) {
          init2()
        } else {
          changeSecList(router.currentRoute)
        }
      }, 1000)
    }

    function changeSecList(to) {
      let secDomlist = document.querySelectorAll(".custom-select-list-item")
      if (secDomlist.length === 0) {
        return
      }
      if (["ChannelCoop", "DevCoop", "EcologicalCoop"].indexOf(to.name) !== -1) {
        secDomlist[0].style.display = "none"
        secDomlist[1].style.display = "block"
        secDomlist[1].click()
        secDomlist[2].style.display = "block"
        secDomlist[3].style.display = "block"
      } else {
        secDomlist[0].style.display = "block"
        secDomlist[0].click()
        secDomlist[1].style.display = "none"
        secDomlist[2].style.display = "none"
        secDomlist[3].style.display = "none"
      }
    }

    router.beforeEach((to, from, next) => {
      // 修改弹窗选项
      changeSecList(to)
      next()
      let lybdom = document.getElementById("nb_nodeboard_close")
      if (lybdom && lybdom.className.indexOf("nb-nodeboard-max") == -1) {
        lybdom.click()
      }
    })
    s.parentNode.insertBefore(hm, s);
  })(window);
}


