export function dateFormat(date, fmt) {
    let o = {
        "[yY]+": date.getFullYear(),
        "M+": date.getMonth() + 1,                 //月份
        "d+": date.getDate(),                    //日
        "h+": date.getHours(),                   //小时
        "m+": date.getMinutes(),                 //分
        "s+": date.getSeconds(),                 //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt))

        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));

    for (var k in o) {
        let re = new RegExp(k)
        if (k === "[yY]+") {
            fmt = fmt.replace(re, ($) => {
                return String(o[k]).slice(0, $.length)
            })
        } else {
            fmt = fmt.replace(re, ($) => {
                return $.length === 1 ? (o[k]) : (("00" + o[k]).slice(String(o[k]).length))
            })
        }
    }
    return fmt;
}